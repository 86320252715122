import './App.css';
import './assets/vendor/font-awesome/css/all.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/vendor/tiny-slider/tiny-slider.css'
import './assets/vendor/glightbox/css/glightbox.css'
import './assets/css/style.css'
import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import OfferRedirect from './components/OfferRedirect'
import LandingLayout from './components/LandingLayout'
import OnlyFormLayout from './components/OnlyFormLayout'
import OnlyFormLayoutShort from './components/OnlyFormLayoutShort'
import OffersListSection from './components/OffersListSection'
import OffersListSectionTinderLayout from './components/OffersListSectionTinderLayout'
import UserProfilePage from './components/UserProfilePage'
import AuthPage from './components/AuthPage'
import ServeFile from "./components/ServeFile";
import SubscribeRedirectNoPayment from "./components/SubscribeRedirectNoPayment";
import Subscribe from "./components/Subscribe";
import RandomOfferRedirect from "./components/RandomOfferRedirect";
import OffersListSectionRandomBackend from "./components/OffersListSectionRandomBackend"
import OffersListSectionBrokers from "./components/OffersListSectionBrokers"
import OfferRedirectBrokers from "./components/OfferRedirectBrokers";
import UserCreditScorePage from "./components/UserCreditScorePage";
import OffersListSectionNew from "./components/OffersListSectionNew";
import OfferRedirectNew from "./components/OfferRedirectNew";
import SingleOfferSelection from "./components/SingleOfferSelection";
import LandingLayoutTG from "./components/LandingLayoutTG";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<LandingLayout/>}/>
                <Route exact path="/bot" element={<LandingLayoutTG/>}/>
                <Route exact path="/form" element={<OnlyFormLayout/>}/>
                <Route exact path="/offers" element={<OffersListSection webmasterId={'1'}/>}/>
                <Route exact path="/offers-swipe" element={<OffersListSectionTinderLayout webmasterId={'1'}/>}/>
                <Route exact path="/ofr" element={<OffersListSectionBrokers webmasterId={'1'} limit={6}/>}/>
                <Route exact path="/rnd" element={<OffersListSection offerType={'sms_page'} cardType={0.1}/>}/>
                <Route exact path="/filter" element={<OffersListSection webmasterId={'1'}/>}/>
                <Route exact path="/go/:offerId" element={<OfferRedirect/>}/>
                <Route exact path="/gon" element={<OfferRedirectNew/>}/>
                <Route exact path="/gob/:offerId" element={<OfferRedirectBrokers/>}/>
                <Route exact path="/rng" element={<RandomOfferRedirect/>}/>

                <Route exact path="/top" element={<OffersListSectionNew webmasterId={'1'}/>}/>
                <Route exact path="/single" element={<SingleOfferSelection webmasterId={'1'}/>}/>

                <Route exact path="/profile/:userId" element={<UserProfilePage/>}/>
                <Route exact path="/credit-score/:userId" element={<UserCreditScorePage/>}/>
                <Route exact path="/login" element={<AuthPage/>}/>


                <Route exact path="/privacy-policy" element={<ServeFile filePath={'privacy-policy.pdf'}/>}/>
                <Route exact path="/policy" element={<ServeFile filePath={'privacy-policy.html'}/>}/>
                <Route exact path="/user-agreement" element={<ServeFile filePath={'user-agreement.pdf'}/>}/>
                <Route exact path="/public-offer-agreement" element={<ServeFile filePath={'public-offer-agreement.pdf'}/>}/>
                <Route exact path="/prices" element={<ServeFile filePath={'prices.pdf'}/>}/>
                <Route exact path="/recurring-payments" element={<ServeFile filePath={'recurring-payments.pdf'}/>}/>

                <Route exact path="/subscribe" element={<SubscribeRedirectNoPayment/>}/>


            </Routes>
        </Router>
    );
}

export default App;
