import React, {useEffect, useState} from 'react';
import HeaderSection from "./HeaderSection";
import StepperSection from "./StepperSection";
import PaymentSection from "./PaymentSection";
import RecommendationsSection from "./RecommendationsSection";
import FAQSection from "./FAQSection";
import {AddedAdsFin} from "../utils";

function LandingSections({handleLoanInit}) {
    const [bannerSize, setBannerSize] = useState("970")
    useEffect(() => {
        if (window.innerWidth < 969) {
            setBannerSize('300')
        }
    }, [])
    return (
        <>
            <HeaderSection handleLoanInit={handleLoanInit}/>
            <StepperSection/>
            {bannerSize && AddedAdsFin() &&
                <div style={{paddingTop: 10}}>
                    <ins className="dd15595d" data-cp-size={bannerSize}
                         data-key="b5d0074139c13195799b45f84f24bdda"/>
                </div>
            }
            <PaymentSection/>
            <RecommendationsSection/>
            <FAQSection handleLoanInit={handleLoanInit}/>
        </>
    )
}

export default LandingSections