import React, {useState, useEffect} from 'react'
import axios from "axios";

const RedirectPage = () => {
    const handleOfferClick = (offerURL, offerID, pos = 1, platform = 'DIRECT') => {
        const ua = window.navigator.userAgent
        const initURL = localStorage.getItem('direction')
        if (initURL) {
            const fullURL = `${initURL}&aff_sub6=${offerID}&aff_sub7=${pos}&aff_sub8=cdn&aff_sub9=${platform}`
            const data = {
                "offerID": offerID,
                "userData": {"ua": ua, 'redirectURL': fullURL, 'platform': platform},
                "position": pos
            }
            axios.post('https://api.ldtrk.ru/api/analytics/v1/click?token=7d0bac20-4bd5-42af-a376-343411facd7b', data)
                .then(res => {
                    console.log(fullURL)
                    window.location.replace(fullURL)
                })
                .catch(err => {
                    console.log(err.response.data)
                })
        } else {
            window.location.replace('https://www.zaymu.life/brokers?aff_sub=tb')
        }
    }

    useEffect(() => {
        const offerURL = localStorage.getItem('direction') || 'https://google.com';
        const offerID = localStorage.getItem('offerID' || 'NA')
        const pos = localStorage.getItem('position' || 0)
        const platform = localStorage.getItem('platform' || 'DIRECT')
        handleOfferClick(offerURL, offerID, pos, platform)
    }, []);

    return (
        <div/>
    )
}

export default RedirectPage