import axios from "axios";


let url = "http://0.0.0.0:5668/api/v2/";
if (process.env.NODE_ENV !== "development") {
  url = 'https://nsvz.simplr.ru/api/v2/'
}

const APIConfig = axios.create({
    baseURL: url,
    responseType: "json"
});

const handleLinkParams = () => {
    const params = new URLSearchParams(window.location.search)
    const paramsDict = {}
    for (const param of params) {
        paramsDict[param[0]] = param[1]
    }
    delete paramsDict["pos"];
    delete paramsDict["ct"];
    if (Object.keys(paramsDict).length) {
       localStorage.setItem('query_params', JSON.stringify(paramsDict))
    }
    let partnerData = JSON.parse(localStorage.getItem('partner_data'))
    if (partnerData && Object.keys(paramsDict).length === 0) {
       console.log('pass')
    } else {
        handlePartnerParams(paramsDict)
    }


}

const handlePartnerParams = (params) => {
    const MAPPING = {
        'sm': {
            'webmaster_position': 0,
            'partner_id': 1,
            'traffic_type': 'sms_page'
        },
        'email': {
            'webmaster_position': 0,
            'partner_id': 1,
            'traffic_type': 'email_page'
        },
        'email_broker': {
            'webmaster_position': 1,
            'partner_id': 1,
            'traffic_type': 'broker'
        },
        'leadgid': {
            'webmaster_position': 1,
            'partner_id': 1,
            'traffic_type': 'broker'
        },
        'finads': {
            'webmaster_position': 1,
            'partner_id': 1,
            'traffic_type': 'broker'
        },
        'guruleads': {
            'webmaster_position': 0,
            'partner_id': 2,
            'traffic_type': 'broker'
        },
        'broker': {
            'webmaster_position': 1,
            'partner_id': 1,
            'traffic_type': 'cpl'
        },
        'bot': {
            'webmaster_position': 1,
            'partner_id': 1,
            'traffic_type': 'broker'
        },
    }
    const localStorageParams = JSON.parse(localStorage.getItem('query_params')) || {}
    let currentSource = params.utm_source || params.source || localStorageParams.source
    let wmPos = 0
    let partnerId = 1
    let trafficType = 'broker'
    if (MAPPING[currentSource]) {
        wmPos = MAPPING[currentSource].webmaster_position
        partnerId = MAPPING[currentSource].partner_id
        trafficType = MAPPING[currentSource].traffic_type
    }
    console.log(wmPos, partnerId, currentSource)
    const partnersPayload = {
        'wm_pos': wmPos,
        'partner_id': partnerId,
        'traffic_type': trafficType
    }
    localStorage.setItem('partner_data', JSON.stringify(partnersPayload))
}

handleLinkParams()

export default APIConfig;