function VisaLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="54"
            fill="none"
            viewBox="0 0 100 54"
        >
            <g>
                <path
                    fill="#00579F"
                    d="M44.667 39.625h-6.481l4.053-25.169h6.48l-4.052 25.169z"
                ></path>
                <path
                    fill="#00579F"
                    d="M68.16 15.072C66.88 14.562 64.853 14 62.346 14c-6.4 0-10.907 3.427-10.934 8.327-.054 3.615 3.226 5.622 5.68 6.828 2.506 1.232 3.359 2.035 3.359 3.133-.026 1.686-2.026 2.464-3.892 2.464-2.587 0-3.974-.401-6.08-1.34l-.854-.401-.907 5.65c1.52.695 4.32 1.312 7.228 1.339 6.8 0 11.227-3.374 11.28-8.595.025-2.866-1.707-5.06-5.44-6.855-2.267-1.151-3.655-1.928-3.655-3.106.026-1.071 1.174-2.169 3.732-2.169 2.107-.053 3.655.455 4.828.964l.586.267.881-5.434z"
                ></path>
                <path
                    fill="#00579F"
                    fillRule="evenodd"
                    d="M79.76 14.456h5.012L90 39.625h-6s-.588-2.892-.774-3.776h-8.32c-.24.67-1.36 3.776-1.36 3.776h-6.8l9.626-23.08c.667-1.634 1.841-2.089 3.387-2.089zm-.4 9.21s-2.054 5.597-2.588 7.043h5.387c-.267-1.259-1.494-7.283-1.494-7.283l-.453-2.169c-.19.558-.466 1.326-.652 1.844-.126.351-.211.587-.2.566z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#00579F"
                    d="M32.773 14.456L26.426 31.62l-.693-3.48c-1.173-4.017-4.853-8.38-8.96-10.55l5.814 22.01h6.852l10.187-25.143h-6.853z"
                ></path>
                <path
                    fill="#FAA61A"
                    d="M20.533 14.456H10.107l-.107.509c8.133 2.088 13.52 7.123 15.733 13.174l-2.267-11.567c-.373-1.607-1.52-2.063-2.933-2.116z"
                ></path>
            </g>
        </svg>
    )
}
export default VisaLogo