function QiwiLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="54"
            fill="none"
            viewBox="0 0 100 54"
        >
            <g>
                <path
                    fill="#FF8C00"
                    fillRule="evenodd"
                    d="M47.445 0C34.496 0 24 10.551 24 23.57s10.495 23.571 23.445 23.571c1.596 0 3.184-.14 4.927-.293 2.018-.178 4.245-.374 6.932-.393 4.465 0 9.372 1.576 14.756 6.373.545.48 1.26-.137.784-.72-5.282-6.748-10.19-8.015-15.097-9.112-5.997-1.337-9.064-4.694-11.211-8.428-.409-.754-.613-.617-.648.343a21.14 21.14 0 00.307 4.214h-.715c-8.554 0-15.506-6.99-15.506-15.589 0-8.6 6.952-15.59 15.505-15.59 8.554 0 15.506 6.99 15.506 15.59 0 .616-.035 1.233-.103 1.816-1.158-.206-3.373-.24-4.94-.103-.58.068-.512.342-.069.411 5.112.959 8.622 4.146 9.44 9.935.034.138.204.172.273.07a23.812 23.812 0 003.305-12.095C70.89 10.552 60.394 0 47.445 0zm18.402 35.63c.137 1.062-.17 1.473-.51 1.473-.342 0-.82-.411-1.33-1.233-.511-.823-.716-1.747-.443-2.228.17-.309.545-.445.989-.273.885.343 1.226 1.678 1.294 2.26zm-4.77 2.33c1.056.89 1.363 1.918.817 2.672-.307.377-.817.616-1.397.616-.58 0-1.193-.205-1.601-.582-.955-.822-1.227-2.194-.615-2.947.24-.308.615-.479 1.091-.479.546 0 1.159.24 1.705.72z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    )
}

export default QiwiLogo