import React, {useState, useEffect} from 'react';
import APIConfig from "../utils/api";
import {
    useParams
} from "react-router-dom";

function RandomOfferRedirect() {
    let {offerId} = useParams();
    const [offerURL, setOfferURL] = useState(null)

    const handleAppParams = (offer = {}) => {
        let queryParams = JSON.parse(localStorage.getItem('query_params')) || {}
        let qp = new URL(window.location.href.toString())
        let aff_sub = qp.searchParams.get('source') || queryParams.utm_source || 'direct'
        let aff_sub6 = queryParams.keyword || queryParams.utm_keyword || '0'
        let aff_sub2 = qp.searchParams.get('campaign') || queryParams.utm_campaign || '0'
        let aff_sub3 = qp.searchParams.get('term') || queryParams.utm_term || '0'
        let aff_sub7 = queryParams.yclid || queryParams.gclid || queryParams.fbclid || offer.name || '0'
        let userId = localStorage.getItem('user_id')
        let aff_sub5 = queryParams.linkPayload || '0'
        let aff_sub4 = userId || 'empty_user_id'
        return `&aff_sub=${aff_sub}&aff_sub2=${aff_sub2}&aff_sub3=${aff_sub3}&aff_sub4=${aff_sub4}&aff_sub5=${aff_sub5}&aff_sub6=${aff_sub6}&aff_sub7=${aff_sub7}`
    }

    const handleOfferLink = (res) => {
        let externalOfferId = res.attributes.leadgid_id
        return `https://fin-lg.com/aff_c?aff_id=35153&offer_id=${externalOfferId}${handleAppParams()}`
    }


    const getOfferData = (res, initIndex) => {
        console.log(res.data.offers_list[initIndex].attributes.leadgid_id, initIndex)
        return res.data.offers_list[initIndex].attributes.leadgid_id
    }

    const getCurrentOffer = () => {
        APIConfig.get('get_user_offers_random?filters[$and][0][traffic_types][slug][$eq]=broker').then(res => {
            let initIndex = 0
            let lgOfferId = getOfferData(res, initIndex)
            console.log(lgOfferId)
            let localOffersList = JSON.parse(localStorage.getItem('local_offers_list'))
            if (localOffersList === null) {
                localOffersList = []
                localOffersList.push(lgOfferId)
                let link = handleOfferLink(res.data.offers_list[initIndex])
                setOfferURL(link)
                window.location.replace(link)
            } else if (localOffersList.includes(lgOfferId)) {
                console.log('ALREADY IN LS')
                let reloadsCount = localStorage.getItem('reloads_count') || 1
                reloadsCount = parseInt(reloadsCount) + 1
                localStorage.setItem('reloads_count', reloadsCount.toString())
                if (reloadsCount > 5) {
                    let params = window.location.search
                    let redirectURL = `https://nsvz.ru/rnd${params}`
                    window.location.replace(redirectURL)
                } else {
                    window.location.reload()
                }
            } else {
                localOffersList.push(lgOfferId)
                let link = handleOfferLink(res.data.offers_list[initIndex])
                setOfferURL(link)
                window.location.replace(link)

            }
            localStorage.setItem('local_offers_list', JSON.stringify(localOffersList))
            console.log(localOffersList, initIndex)

        })

    }
    useEffect(() => {
        getCurrentOffer()
    }, [])

    return (
        <>
            <p></p>
            {/*<p>{offerURL}</p>*/}
        </>
    )
}

export default RandomOfferRedirect;
