import React, {useState, useEffect} from 'react';



function ServeFile({filePath}) {
    useEffect(() => {
        window.location.replace(filePath)
    }, [])
    return (
        <p></p>
    )
}

export default ServeFile;