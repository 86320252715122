function MaestroLogo() {
    return (
            <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="54"
      fill="none"
      viewBox="0 0 100 54"
    >
      <g>
        <g>
          <path
            fill="#00A2E5"
            fillRule="evenodd"
            d="M84.817 40.51v.744h-.148v-.744h-.296v-.15h.74v.15h-.296zm1.48-.15v.894h-.111v-.67l-.259.596h-.185l-.259-.596v.67h-.148v-.893h.222l.259.595.259-.595h.222z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#7375CF"
            d="M59.99 45.087H39.973V8.913H59.99v36.174z"
          ></path>
          <path
            fill="#EB001B"
            d="M41.268 27A22.986 22.986 0 0150 8.913 22.636 22.636 0 0035.866 4C23.249 4 13 14.31 13 27s10.249 23 22.866 23A22.636 22.636 0 0050 45.087 22.987 22.987 0 0141.268 27z"
          ></path>
          <path
            fill="#00A2E5"
            d="M87 27c0 12.69-10.249 23-22.866 23A22.636 22.636 0 0150 45.087 22.987 22.987 0 0058.732 27 22.986 22.986 0 0050 8.913 22.636 22.636 0 0164.134 4C76.751 4 87 14.31 87 27z"
          ></path>
        </g>
      </g>
    </svg>
    )
}

export default MaestroLogo