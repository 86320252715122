import React, {useState, useEffect} from 'react';
import APIConfig from "../utils/api";
import {
    useParams
} from "react-router-dom";
import PageHeader from "./PageHeader";

function OfferRedirect() {
    let {userId} = useParams();
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState({})
    const [userSubscriptions, setUserSubscriptions] = useState({})
    const [userApplications, setuUerApplications] = useState([])
    const [showUnsubscribePopup, setShowUnsubscribePopup] = useState(false)
    const [showDonePopup, setShowDonePopup] = useState(false)
    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null)

    const getUserData = () => {
        const tkn = localStorage.getItem('access_token')
        if (!tkn) {
            window.location.replace('/login')
        }
        const headers = {
            'Authorization': `Bearer ${tkn}`
        }
        APIConfig.get(`get_client_by_id?user_id=${userId}`, {
            headers: headers
        }).then(res => {
            setUserData(res.data.user)
            setUserSubscriptions(res.data.subscriptions)
            setuUerApplications(res.data.applications)
            setLoading(false)
        })
            .catch(err => {
                if (err.response.status === 401 || err.response.status === 422) {
                    window.location.replace('/login')
                }
            })
    }
    useEffect(() => {
        getUserData()
    }, [])

    const handleSubscriptionPopUp = (subscriptionId) => {
        setSubscriptionToCancel(subscriptionId)
        setShowUnsubscribePopup(true)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    }

    const stopSubscription = () => {
        console.log(subscriptionToCancel)
        const tkn = localStorage.getItem('access_token')
        if (!tkn) {
            window.location.replace('/login')
        }
        const headers = {
            'Authorization': `Bearer ${tkn}`
        }
        APIConfig.get(`cancel_user_subscription?user_id=${userId}&subscription_id=${subscriptionToCancel}`, {
            headers: headers
        }).then(res => {
            setSubscriptionToCancel(null)
            setShowUnsubscribePopup(false)
            setShowDonePopup(true)
            setTimeout(() => {window.location.reload()}, 15000)
            console.log(res)
        })

    }

    return (
        <>
            {showUnsubscribePopup &&
                <div className='popup-wrapper'>
                    <div className='popup-body'>
                        <h5>Вы уверены, что хотите отменить подписку?</h5>
                        <p>После отмены подписки - мы перестанем оказывать услуги по подбору займа!</p>
                        <button onClick={() => setShowUnsubscribePopup(false)}
                                className={'btn btn-primary form-control'}>
                            Оставить подписку
                        </button>

                        <button onClick={() => stopSubscription()}
                                className={'btn btn-default form-control mt-3'}>
                            Отменить подписку
                        </button>
                    </div>
                </div>
            }

            {showDonePopup &&
                <div className='popup-wrapper'>
                    <div className='popup-body'>
                        <h5>Подписка отменена</h5>
                        <p>Статус подписки изменится в течение 3-5 минут. Обновите страницу позже</p>
                    </div>
                </div>
            }

            {loading
                ? <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                </section>
                : <>
                    <PageHeader currentStep={9999}/>
                    <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                        <div className='container'>
                            <h1>Привет, {userData.base_data.first_name}</h1>

                            <div className='row mt-3'>
                                <h3>Управление подписками</h3>
                                {Object.keys(userSubscriptions).map(subscription => {
                                    return (
                                        <div className='col-md-3 mb-3'>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">Подписка
                                                        "{userSubscriptions[subscription][0].description}"</h5>
                                                    <p className='mb-0 mt-3'>Список транзакций</p>
                                                    <ul style={{listStyle: 'none', paddingLeft: 0}}>
                                                        {userSubscriptions[subscription].map(transaction => {
                                                            return (
                                                                <li>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'start',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        {
                                                                            transaction.status === '3' || transaction.status === '4'
                                                                                ? <img src='/checked.png'
                                                                                       style={{marginRight: 10}}
                                                                                       width={20}/>
                                                                                : <img src='/cancel.png'
                                                                                       style={{marginRight: 10}}
                                                                                       width={20}/>
                                                                        }

                                                                        <img width={30}
                                                                             src={`/${transaction.card_type.toLowerCase()}.png`}/>
                                                                        <span style={{fontSize: 16, marginLeft: 5}}>
                                                                                {transaction.card_first_six}****{transaction.card_last_four}
                                                                            </span>
                                                                        <span style={{fontSize: 16, marginLeft: 10}}>
                                                                                {transaction.amount}₽
                                                                            </span>

                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    {userSubscriptions[subscription][0].current_status ?
                                                        <button onClick={() => handleSubscriptionPopUp(subscription)}
                                                                className={'btn btn-primary form-control'}>
                                                            Отменить подписку
                                                        </button>
                                                        :
                                                        <button disabled={true}
                                                                className={'btn btn-disabled form-control'}>
                                                            Подписка отменена
                                                        </button>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })}


                            </div>


                            <div className='row mt-1 mb-5'>
                                <div className='col-md-12 mb-3'>
                                    <h4>Список ваших заявок на займ</h4>
                                </div>

                                {userApplications.length ?
                                    <>
                                        {userApplications.map(application => {
                                            return (
                                                <div className='col-md-3 mb-3'>
                                                    <div className="card">
                                                        <img
                                                            src={`https://storage.yandexcloud.net/ezflow-bucket/mfo_logo/${application.dm_offer_id}.png`}
                                                            className="card-img-top"
                                                            alt="..."
                                                        />
                                                        <div className="card-body">
                                                            {
                                                                application.mt_leads_approved === 1
                                                                    ?
                                                                    <h5 className="card-title text-success">Одобрен</h5>
                                                                    : <h5 className="card-title">На рассмотрении...</h5>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </>
                                    : <p>Вы еще не оставляли заявок на займ. Посмотрите <a href='/offers'> персональные
                                        предложения </a></p>
                                }
                            </div>

                            <div className='row mt-1 mb-0'>
                                <div className='col-md-12 mt-5'>
                                    <h5>Остались вопросы или сложности с подпиской?</h5>
                                    <p className='mt-0 mb-0'>Напишите нам по адресу support@nsvz.ru – мы ответим в
                                        кротчайшие
                                        сроки</p>
                                </div>
                            </div>

                        </div>
                    </section>
                </>

            }

        </>
    )
}

export default OfferRedirect;