import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";


const AdsFinBanner = ({bannerId}) => {
   const adDivRef = useRef(null);

    useEffect(() => {
        console.log('adDivRef.current ', adDivRef.current)
        if (!adDivRef.current) {
            return;
        }
        const script = document.createElement('script');
        script.src = `https://front.adsfin.net/place/${bannerId}/`;
        script.async = true;
        adDivRef.current.appendChild(script);
    }, []);

    return (
        <div ref={adDivRef} style={{marginTop: 10, marginBottom: 10}}>
            <ins id={`place_${bannerId}`}/>
        </div>
    )
};

export default AdsFinBanner;