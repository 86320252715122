import React, {useState} from 'react';
import {getNoun} from "../utils";

function AdvCardItemNew({offer, position}) {

    function createLeadID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    async function handleLeadID() {
        let leadID = localStorage.getItem('leadID') || null
        if (leadID) {
            return leadID
        } else {
            leadID = createLeadID()
            localStorage.setItem('leadID', leadID)
            return leadID
        }
    }

    function stringifyFilteredQueryParameters() {
        const queryParamsObject = JSON.parse(localStorage.getItem('query_params') || '{}');
        const filteredParams = {};

        for (const key in queryParamsObject) {
            if (key.includes('aff_sub') || key === 'sub' || key === 'sub1' || key === 'sub2' || key === 'sub3' || key === 'sub4') {
                filteredParams[key] = queryParamsObject[key];
            }
        }

        const filteredParamsString = Object.entries(filteredParams)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        return filteredParamsString;
    }

    function handleOfferClick(url, offerID, position = 0) {
        const location = window.location;
        const params = new URLSearchParams(location.search);
        let platform = 'EMAIL'
        if (params.has('platform')) {
            platform = params.get('platform')
        }
        let URLParams = localStorage.getItem('query_params');
        console.log('URLParams', URLParams)
        let linkExtra = null
        if (URLParams) {
            linkExtra = stringifyFilteredQueryParameters(URLParams)
        }
        console.log(url, offerID, position, platform, URLParams, linkExtra)



        handleLeadID().then(res => {
            if (linkExtra) {
                console.log(linkExtra, 'linkExtra')
                linkExtra = `${linkExtra}&aff_sub5=${res}&sub5=${res}`
                localStorage.setItem('direction', `${url}&${linkExtra}`)
                localStorage.setItem('offerID', offerID)
                localStorage.setItem('position', position + 1)
                localStorage.setItem('platform', platform)
            } else {
                localStorage.setItem('direction', `${url}&sub5=${res}`)
                localStorage.setItem('offerID', offerID)
                localStorage.setItem('position', position + 1)
                localStorage.setItem('platform', platform)
            }
        })


    }

    const randomLoans = () => {
        const loans = Math.round(Math.round(Math.random() * 100) * 4.2)
        const noun = getNoun(loans, ' займ ', ' займа ', ' займов ')
        return `${loans}${noun}`
    }
    return (

        <a href={'/gon'} target='_blank' onClick={() =>
            handleOfferClick(
                offer.offerUrl,
                offer.uid,
                position
            )}>
            <div className="card mb-3 shadow">
                <img
                    src={offer.offerImageUrl}
                    className="card-img-top"
                    alt={offer.offerName}
                    title={offer.offerName}
                />
                <div className="card-body flat-card-body">
                    <div className="d-flex justify-content-between mb-2">
                    <span className={`form-control border-0 badge bg-success bg-opacity-10 text-success py-2`}>
                        {offer.offerName}
                    </span>
                    </div>
                    <h5 className="card-title mt-3">Сумма до {offer.amountTo.toLocaleString()} ₽</h5>
                    <p className="card-text text-black">
                        – на срок до {offer.loanPeriod} дней
                    </p>

                    <p className="card-text text-black">
                        – ставка от {offer.dailyRate}%
                    </p>

                    <p className="card-text text-black">
                        – возраст от 18 лет
                    </p>

                    <ul className="list-inline mb-3">
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        {(Math.round(Math.random() * 10) % 3) >= 1
                            ?
                            <li className="list-inline-item me-0 ">
                                <i className="fas fa-star text-warning"/>
                            </li>
                            :
                            <li className="list-inline-item me-0 ">
                                <i className="fas fa-star-half-alt text-warning"/>
                            </li>
                        }
                    </ul>

                    <div className="card-footer pt-0 pb-3" style={{marginRight: '-11px', marginLeft: '-15px'}}>
                        <hr/>
                        <div className="d-flex justify-content-between">
                            <span className="h6 fw-light mb-0" style={{fontSize: '14px'}}>
                                <i className="far fa-regular fa-thumbs-up text-danger me-2"/>
                                {randomLoans()} одобрили сегодня
                            </span>
                        </div>
                    </div>

                    <button className="btn btn-lg btn-danger-soft form-control">
                        Получить деньги
                    </button>
                </div>


            </div>
        </a>
    )
}

export default AdvCardItemNew