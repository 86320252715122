import React, {useState, useEffect} from 'react';
import AdvCardItemNew from "./AdvCardItemNew";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import APIConfig from "../utils/api";
import {uuidv4, handleOffersFilter, AddedAdsFin} from "../utils";
import CustomAd from "./CustomAds";
import axios from "axios";
import AdsFinBanner from "./AdsFinBanner";

function OffersListSectionNew({cardType = Math.random()}) {
    const [showPreloader, setShowPreloader] = useState(true)
    const [offersList, setOffersList] = useState(null)
    const [filterParams, setFilterParams] = useState(null)
    const [offerUrlParams, setOfferUrlParams] = useState(null)
    const [secondBanner, setSecondBanner] = useState(false)

    const [bannerSize, setBannerSize] = useState("970")
    useEffect(() => {
        if (window.innerWidth < 969) {
            setBannerSize('300')
        }
    }, [])

    function convertToQueryParameters(data) {
        if (!Array.isArray(data)) {
            return '';
        }
        const parameters = data.map(item => `offers_filter=${item.offer_id}`).join('&');
        console.log(parameters)
        return `&${parameters}`;
    }

    function handleLinkParams() {
        const params = new URLSearchParams(window.location.search)
        const paramsDict = {}
        for (const param of params) {
            paramsDict[param[0]] = param[1]
        }
        if (Object.keys(paramsDict).length) {
            localStorage.setItem('query_params', JSON.stringify(paramsDict))
        }
        if (params.has('offers_filter')) {
            convertToQueryParameters(params)
            let paramsString = params.get('offers_filter').split(',').map(item => `offers_filter=${item}`).join('&');
            setFilterParams(paramsString)
        }
    }

    const handleURLParams = async () => {
        const location = window.location;
        const params = new URLSearchParams(location.search);
        let sum = 0;
        let period = 0;

        let offerURLParams = new URLSearchParams(location.search)

        if (params.has('sum')) {
            sum = params.get('sum');
            offerURLParams.delete('sum')
        }

        if (params.has('period')) {
            period = params.get('period');
            offerURLParams.delete('period')
        }
        return [offerURLParams.toString(), sum, period]
    }

    const getUserOffers = () => {
        const location = window.location;
        const params = new URLSearchParams(location.search);
        let platform = 'EMAIL'
        if (params.has('platform')) {
            platform = params.get('platform')
        }
        axios.get(`https://api.ldtrk.ru/api/content/v1/get_content/offer?token=7d0bac20-4bd5-42af-a376-343411facd7b&platform=${platform}&rnd=true`).then(res => {
            setOffersList(res.data.content)
            setShowPreloader(false)
        })
    }

    const getUserOffersFilter = (filterString) => {
        axios.get(`https://api.ldtrk.ru/api/content/v1/get_content/offer?token=7d0bac20-4bd5-42af-a376-343411facd7b&${filterString}`).then(res => {
            setOffersList(res.data.content)
            setShowPreloader(false)
        })
    }

    const handleOfferImpressions = () => {
        if (offersList) {
            const params = handleURLParams()
            setOfferUrlParams(params[0])
            const location = window.location;

            const paramsLocal = new URLSearchParams(location.search);
            let platform = 'EMAIL'
            if (paramsLocal.has('platform')) {
                platform = paramsLocal.get('platform')
            }

            const impressions = offersList.map((offer, pos) => {
                return {
                    "offerID": offer.uid,
                    "position": pos + 1,
                    "platform": platform
                }
            })
            APIConfig.post('https://api.ldtrk.ru/api/analytics/v1/impressions?token=7d0bac20-4bd5-42af-a376-343411facd7b',
                impressions).then(res => {
                console.log(res)
            })
        }
    }

    useEffect(() => {
        handleOfferImpressions()
    }, [offersList])


    useEffect(() => {
        const OFFERS_LIST_ID = uuidv4()
        localStorage.setItem('offers_list_id', OFFERS_LIST_ID)
        if (localStorage.getItem('user_id') === null) {
            localStorage.setItem('user_id', uuidv4())
            localStorage.setItem('inited_from_offers_list', 'true')
        }
        handleLinkParams()

        setTimeout(() => {
            if (window.location.pathname === '/ofr') {
                setSecondBanner(true)
            }
            const params = new URLSearchParams(window.location.search)
            if (params.has('offers_filter')) {

                console.log('params.has(\'offers_filter\')', params.has('offers_filter'))
                let paramsString = params.get('offers_filter').split(',').map(item => `offers_filter=${item}`).join('&');
                getUserOffersFilter(paramsString)
            } else {
                getUserOffers()
            }
        }, 1000)


    }, [])
    return (
        <>
            {showPreloader ?
                <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                    <p className='mt-3'>Подбираем персональные предложения...</p>
                </section>
                : <>
                    <PageHeader currentStep={4}/>
                    <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                        <div className='container'>
                            <div className='row mt-2 mb-5'>
                                <div className="col-lg-8 mx-auto text-center">
                                    <h2 className="fs-1">Персональные предложения 🔥</h2>
                                    <p className="mb-0">
                                        Для гарантированного получения займа, нужно оставить заявку минимум в 3 МФО
                                    </p>
                                </div>
                            </div>

                            <div className='row'>
                                {offersList.map((offer, position) => {
                                    return (
                                        <>
                                            <div className='col-md-3 col-sm-12 mb-3 mb-md-0' key={offer.id}>
                                                <AdvCardItemNew offer={offer} position={position + 1}/>
                                            </div>
                                            {(position + 1) % 2 === 0 && <AdsFinBanner bannerId='131969518832209118'/>}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                    <PageFooter currentStep={4}/>
                </>
            }
        </>
    )
}

export default OffersListSectionNew