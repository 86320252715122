import React, {useState} from 'react';
import SlidersBlock from "./SlidersBlock";
import VisaLogo from "./logos/VisaLogo";
import QiwiLogo from "./logos/QiwiLogo";
import YandexLogo from "./logos/YandexLogo";
import MirLogo from "./logos/MirLogo";
import MasterCardLogo from "./logos/MasterCardLogo";
import MaestroLogo from "./logos/MaestroLogo";

function PaymentSection() {
    return (
        <section className="py-0 py-lg-5" id="paymentSection" >
            <div className="container">
                <div className="row">
                    <div className="col-12 position-relative z-index-1">

                        <div
                            className="payment__wrapper p-4 p-sm-5 rounded position-relative z-index-n1 overflow-hidden text-center">
                            <h2 className='mb-5 mt-0'>Способы получения займа</h2>


                            <div className="row align-items-end justify-content-end position-relative">
                                <div className="col-lg-12">
                                    <div className="row g-3 d-flex align-items-center justify-content-center">


                                        <div className="col-md-2 col-sm-6 text-center">
                                            <div className="d-flex justify-content-center">
                                                <VisaLogo/>
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-6 text-center">
                                            <div className="d-flex justify-content-center">
                                                <QiwiLogo/>
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-6 text-center">
                                            <div className="d-flex justify-content-center">
                                                <YandexLogo/>
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-6 text-center">
                                            <div className="d-flex justify-content-center">
                                                <MirLogo/>
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-6 text-center">
                                            <div className="d-flex justify-content-center">
                                                <MasterCardLogo/>
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-6 text-center">
                                            <div className="d-flex justify-content-center">
                                                <MaestroLogo/>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentSection