import React from 'react';
import {Controller} from "react-hook-form";
import InputMask from "react-input-mask";

function PassportData({errors, register, control}) {
    const eduList = [
        {'title': 'Неполное среднее', 'value': 1},
        {'title': 'Среднее', 'value': 2},
        {'title': 'Среднее-специальное', 'value': 3},
        {'title': 'Неполное высшее', 'value': 4},
        {'title': 'Два и более высших', 'value': 5},
        {'title': 'Высшее', 'value': 6},
        {'title': 'Ученая степень', 'value': 7},
        {'title': 'Другое', 'value': 8},
        {'title': 'Отсутствует', 'value': 9},
    ]
    return (
        <>
            <div className="col-md-6 col-sm-12">
                <label className="form-label application-form-label">Серия паспорта</label>

                <Controller
                    as={InputMask}
                    render={
                        ({field}) =>
                            <InputMask mask="99 99" type="tel"
                                       autoComplete="new-password" inputRef={field.ref}
                                       className="form-control" {...field}/>
                    }
                    control={control}
                    defaultValue=""
                    rules={{required: 'обязательное поле'}}
                    name="passportSeries"

                />
                {errors.passportSeries &&
                    <span className='validation__error'>{errors.passportSeries.message}</span>}
            </div>
            <div className="col-md-6 col-sm-12">
                <label className="form-label application-form-label">Номер паспорта</label>

                <Controller
                    as={InputMask}
                    render={
                        ({field}) =>
                            <InputMask mask="999 999" type="tel"
                                       autoComplete="new-password" inputRef={field.ref}
                                       className="form-control" {...field}/>
                    }
                    control={control}
                    defaultValue=""
                    rules={{required: 'обязательное поле'}}
                    name="passportNumber"

                />
                {errors.passportNumber &&
                    <span className='validation__error'>{errors.passportNumber.message}</span>}

            </div>
            <div className="col-md-6 col-sm-12">
                <label className="form-label application-form-label">Код подразделения</label>

                <Controller
                    as={InputMask}
                    render={
                        ({field}) =>
                            <InputMask mask="999-999" type="tel"
                                       autoComplete="new-password" inputRef={field.ref}
                                       className="form-control" {...field}/>
                    }
                    control={control}
                    defaultValue=""
                    rules={{required: 'обязательное поле'}}
                    name="passportCode"

                />
                {errors.passportCode &&
                    <span className='validation__error'>{errors.passportCode.message}</span>}

            </div>
            <div className="col-md-6 col-sm-12">
                <label className="form-label application-form-label">Дата выдачи</label>

                <Controller
                    as={InputMask}
                    render={
                        ({field}) =>
                            <InputMask mask="99.99.9999" type="tel"
                                       autoComplete="new-password" inputRef={field.ref}
                                       className="form-control" {...field}/>
                    }
                    control={control}
                    defaultValue=""
                    rules={{required: 'обязательное поле'}}
                    name="passportDate"

                />
                {errors.passportDate &&
                    <span className='validation__error'>{errors.passportDate.message}</span>}

            </div>

            <div className="col-12">
                <label className="form-label application-form-label">Место рождения</label>
                <input type="text"
                       className="form-control" {...register("birthPlace", {required: 'обязательное поле'})}/>
                {errors.birthPlace &&
                    <span className='validation__error'>{errors.birthPlace.message}</span>}
            </div>

            <p className='mb-0'>Ваш пол</p>
            <div className="radio-wrapper mb-2 mt-1">

                <div className="">
                    <input type="radio" className="form-check-input"
                           id="girlGender" {...register("selectedGender",
                        {required: 'необходимо согласиться с условиями'})} value={'girl'}/>
                    <label className="form-check-label" htmlFor="girlGender">
                        Женский
                    </label>
                    {errors.paymentAccept &&
                        <span className='validation__error'>{errors.paymentAccept.message}</span>}
                </div>

                <div className="mx-3">
                    <input type="radio" className="form-check-input"
                           id="boyGender" {...register("selectedGender",
                        {required: 'необходимо согласиться с условиями'})} value={'boy'}/>
                    <label className="form-check-label" htmlFor="boyGender">
                        Мужской
                    </label>
                    {errors.paymentAccept &&
                        <span className='validation__error'>{errors.paymentAccept.message}</span>}
                </div>
            </div>

            <div className="col-12">
                <label className="form-label application-form-label">Образование</label>
                <select className='form-select ' {...register("education", {required: 'обязательное поле'})}>
                    {eduList.map(value => (
                        <option key={value.value} value={value.value}>
                            {value.title}
                        </option>
                    ))}
                </select>
            </div>

        </>
    )
}

export default PassportData