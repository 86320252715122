import React, {useState, useEffect} from 'react';
import APIConfig from "../utils/api";
import {
    useParams
} from "react-router-dom";

function OfferRedirect() {
    let {offerId} = useParams();
    const [offerURL, setOfferURL] = useState(null)

    const handleAppParams = (offer = {}) => {
        let queryParams = JSON.parse(localStorage.getItem('query_params')) || {}
        let aff_sub = queryParams.source || queryParams.utm_source || 'direct'
        let aff_sub6 = queryParams.keyword || queryParams.utm_keyword || 'none'
        let aff_sub2 = queryParams.campaign || queryParams.utm_campaign || queryParams.click_id || 'none'
        let aff_sub3 = queryParams.term || queryParams.utm_term || queryParams.web_id || 'none'
        let aff_sub7 = queryParams.yclid || queryParams.gclid || queryParams.fbclid || offer.slug || 'none'
        let userId = localStorage.getItem('user_id')
        let aff_sub5 = queryParams.linkPayload || ''
        let aff_sub4 = userId || 'empty_user_id'
        return `&aff_sub=${aff_sub}&aff_sub2=${aff_sub2}&aff_sub3=${aff_sub3}&aff_sub4=${aff_sub4}&aff_sub5=${aff_sub5}&aff_sub6=${aff_sub6}&aff_sub7=${aff_sub7}`
    }

    const handleOfferLink = (res) => {
        let domain = res.data.offer.attributes.cpa_partner_domain
        let webmasterId = res.data.offer.attributes.cpa_web_id
        let externalOfferId = res.data.offer.attributes.cpa_offer_id
        let link = `${domain}/aff_c?offer_id=${externalOfferId}&aff_id=${webmasterId}${handleAppParams(res.data.offer)}`
        let queryParams = JSON.parse(localStorage.getItem('query_params')) || {}
        if (queryParams.utm_source === 'guruleads') {
            let handledParams = handleAppParams(res.data.offer.attributes)
            handledParams = handledParams.replaceAll('aff_sub', 'sub')
            handledParams = handledParams.replace('sub=', 'sub1=')
            handledParams = handledParams.replaceAll('&sub1=', '?sub1=')
            link = `${domain}/${webmasterId}/${externalOfferId}${handledParams}`
        }
        return link
    }

    const getCurrentOffer = () => {
        const partnerData = JSON.parse(localStorage.getItem('partner_data'))
        let partnerId = 1
        let wmPos = 0
        if (partnerData) {
            partnerId = partnerData.partner_id
            wmPos = partnerData.wm_pos
        }
        APIConfig.get(`get_offer_by_id?offer_id=${offerId}&partner_id=${partnerId}&wm_pos=${wmPos}`)
            .then(res => {
                const offerLink = handleOfferLink(res)
                window.location.replace(offerLink)
                setOfferURL(handleOfferLink(res))
                try {
                    window.ym(85181272, 'reachGoal', 'offer_redirect')
                } catch (error) {
                    console.error(error);
                }
        })
    }
    useEffect(() => {
        getCurrentOffer()
    }, [])

    return (
        <>
            <p></p>
            {/*<p>{offerURL}</p>*/}
        </>
    )
}

export default OfferRedirect;