import React, {useEffect, useState} from "react";
import PageHeader from "./PageHeader";
import LandingSections from "./LandingSections";
import PageFooter from "./PageFooter";


function LandingLayout() {
    const [showPreloader, setShowPreloader] = useState(true)
    const [stepCount, setStepCount] = useState(0)

    const initUser = (userData, userId) => {
        userData['query_params'] = JSON.parse(localStorage.getItem('query_params'))
        localStorage.setItem('initedUserData', JSON.stringify(userData))
        setStepCount(1)
        localStorage.setItem('current_step', '1')
        localStorage.removeItem('inited_from_offers_list')
        // let params = '&source=broker'
        // if (userData.query_params) {
        //     let paramsString = ''
        //     Object.keys(userData.query_params).forEach((key, value) => {
        //         paramsString = paramsString.concat(`&${key}=${userData.query_params[key]}`)
        //     })
        //     params = paramsString
        // }
        setTimeout(() => {
            window.location.replace(`ofr`)
        }, 100)
    }


    const handleLoanInit = (userId) => {
        window.ym(85181272, 'reachGoal', `init_loan`)
        const uuid = userId
        localStorage.setItem('user_id', uuid)
        const userData = {
            'user_uid': uuid,
            'current_step': 1,
            'user_agent': navigator.userAgent,
            'loan_amount': localStorage.getItem('loan_amount') || '10000',
            'loan_period': localStorage.getItem('loan_period') || '30'
        }
        initUser(userData, uuid)
    }


    useEffect(() => {
        setShowPreloader(false)
    }, [])


    return (
        <>
            {showPreloader ?
                <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                </section>
                : <>
                    <PageHeader currentStep={stepCount}/>
                    <LandingSections handleLoanInit={handleLoanInit}/>
                    <PageFooter currentStep={stepCount}/>

                </>
            }
        </>
    );
}

export default LandingLayout
