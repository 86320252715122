import React, {useState} from 'react';
import FormStepOne from "./FormStepOne";
import AddressData from "./FormStepTwo";
import FormStepTwoSMS from "./FormStepTwoSMS";
import PassportData from "./FormStepThree";
import {FaAngleRight} from "react-icons/fa";

function FormSection({handleSubmit, onSubmit, stepCount, control, errors, register, setValue}) {
    return (
        <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                <div className='container'>
                    <div className="row mb-3">
                        <div className="col-lg-8 mx-auto text-center"><h2 className="fs-1">Заполнить заявку на займ</h2></div>
                    </div>

                    <div className='row align-items-center g-5'>
                        <div className='col-lg-12 position-relative z-index-1 form__wrapper mb-5 mb-sm-0'>

                            <div className="bg-white rounded-3 p-4 p-sm-5 form__flex">
                                <form className="row g-4 g-sm-3 mt-2 mb-0" onSubmit={handleSubmit(onSubmit)}>

                                    {stepCount === 1 && <FormStepOne control={control} errors={errors} register={register}/>}
                                    {stepCount === 2 && <FormStepTwoSMS control={control} errors={errors} register={register} setValue={setValue}/>}
                                    {stepCount === 4 && <AddressData control={control} errors={errors} register={register} setValue={setValue}/>}
                                    {stepCount === 3 && <PassportData control={control} errors={errors} register={register}/>}

                                    <div className="col-12 d-grid">
                                        <button type="submit" className="btn btn-lg btn-danger-soft text-white mb-0">
                                            Продолжить <FaAngleRight/>
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
    )
}

export default FormSection