import React, {useState, useEffect} from 'react';
import ReactSpeedometer from "react-d3-speedometer"
import APIConfig from "../utils/api";
import {
    useParams
} from "react-router-dom";
import PageHeader from "./PageHeader";
import AdvCardItem from "./AdvCardItem";
import {AddedAdsFin} from "../utils";

function UserCreditScorePage() {
    let {userId} = useParams();
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState({})
    const [userSubscriptions, setUserSubscriptions] = useState({})
    const [userApplications, setuUerApplications] = useState([])
    const [showUnsubscribePopup, setShowUnsubscribePopup] = useState(false)
    const [showDonePopup, setShowDonePopup] = useState(false)
    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null)

    const [creditScore, setCreditScore] = useState(450)
    const [creditScoreColor, setCreditScoreColor] = useState("#fd564d")
    const [offersList, setOffersList] = useState([])

    const [bannerSize, setBannerSize] = useState("970")
    useEffect(() => {
        if (window.innerWidth < 969) {
            setBannerSize('300')
        }
    }, [])

    const handleCreditRating = (rating) => {
        if (rating < 499) {
            setCreditScoreColor("#fd564d")
        }
        if (rating > 499 && rating < 749) {
            setCreditScoreColor("#f7c32e")
        }
        if (rating > 750) {
            setCreditScoreColor("#0fbc87")
        }
    }

    const getUserData = () => {
        APIConfig.get(`get_user_by_bot_user_id?bot_user_id=${userId}`).then(res => {
            try {
                handleCreditRating(res.data.personal_data.credit_rating)
                setCreditScore(res.data.personal_data.credit_rating)
                setLoading(false)
            } catch (err) {
                window.location.replace('/')
            }
        })
    }

    const getUserOffers = () => {
        APIConfig.get(`get_user_offers?filters[$and][0][traffic_types][slug][$eq]=broker`).then(res => {
            const randomOffers = res.data.offers_list.sort(function (a, b) {
                return 0.5 - Math.random()
            })
            setOffersList(randomOffers.slice(0, 1000))
        })
    }

    useEffect(() => {
        getUserData()
        getUserOffers()
    }, [])

    return (
        <>
            <PageHeader currentStep={9}/>
            <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                <div className='container card pt-4'>
                    <div className="card-body">
                        {loading
                            ? <p>Загружаю ваш кредитный отчет</p>
                            : <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3 style={{
                                                fontSize: 48,
                                                marginTop: 30,
                                                marginBottom: 20
                                            }}>Ваш кредитный рейтинг</h3>
                                            <h2 style={{
                                                fontSize: 64,
                                                marginTop: 30,
                                                marginBottom: 20,
                                                color: creditScoreColor
                                            }}>{creditScore}</h2>
                                            <ReactSpeedometer
                                                maxValue={1000}
                                                minValue={250}
                                                value={creditScore}
                                                segments={3}
                                                customSegmentStops={[250, 500, 750, 1000]}
                                                segmentColors={['#fd564d', "#f7c32e", "#0fbc87"]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 className='mb-3'>Шкала рейтинга</h3>
                                            <h4 style={{color: '#fd564d'}}>250 – 500</h4>
                                            <p>
                                                Оценка крайне неблагоприятная. В традиционных банках вам, скорее всего,
                                                откажут в кредите, и придется обращаться за финансированием в
                                                микрофинансовые организации, кредитные потребительские кооперативы и
                                                ломбарды.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className='text-warning'>500 – 750</h4>
                                            <p>
                                                Возможность отказа значительная. Вам, возможно, придется рассчитывать
                                                лишь
                                                на дорогие кредиты определенного типа (товарные, потребительские) с
                                                большим сроком погашения.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className='text-success'>750 – 1000</h4>
                                            <p>
                                                Это превосходный показатель вашей финансовой устойчивости. С таким
                                                рейтингом вы находитесь в позиции выбирать банки, а не наоборот. Вы
                                                можете
                                                искать наиболее выгодные кредитные условия с сниженными процентными
                                                ставками.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className='container card mt-2'>
                    <div className="card-body">
                        <div className="row">
                            {bannerSize && AddedAdsFin() &&
                                <div style={{paddingTop: 10}}>
                                    <ins className="dd15595d" data-cp-size={bannerSize}
                                         data-key="1696297e5a611991e93d495bfed496e7"/>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='container card mt-2'>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Ваша персональная подборка</h4>
                                <p>Опираясь на ваш кредитный рейтинг, мы отобрали для вас банки и МФО с наибольшей
                                    вероятностью одобрения вашей заявки.</p>
                                <p className="text-success text-info">Хотя мы подобрали предложения, учитывая ваш
                                    кредитный рейтинг, мы рекомендуем подать
                                    заявки минимум в 3 финансовых организации, чтобы максимально повысить шансы на
                                    одобрение.</p>
                            </div>
                        </div>
                        <div className="row">
                            {offersList.map((offer, position) => {
                                return (
                                    <div className='col-md-3 col-sm-12 mb-3 mb-md-0' key={offer.id}>
                                        <AdvCardItem
                                            imgAlt={offer.attributes.name}
                                            imgSource={offer.attributes.image_url}
                                            imgTitle={offer.attributes.name}
                                            badgeText={offer.attributes.badge.data.attributes.text}
                                            badgeClass={offer.attributes.badge.data.attributes.class}
                                            offerLink={`/gob/${offer.id}`}
                                            loanAmount={`до ${offer.attributes.amount_to.toLocaleString()} руб.`}
                                            cardText={`от ${offer.attributes.rate}% в день`}
                                            offerPosition={position + 1}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default UserCreditScorePage;