function MirLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="54"
            fill="none"
            viewBox="0 0 100 54"
        >
            <g>
                <path
                    fill="#0F754E"
                    fillRule="evenodd"
                    d="M10.155 13.005l.14-.002c.995-.017 3.427-.059 4.486 3.517.75 2.53 1.945 6.676 3.584 12.436h.668a2492.47 2492.47 0 013.623-12.436c1.058-3.57 3.607-3.534 4.843-3.517l.22.002h8.68V40h-8.847V24.09h-.593L22.027 40h-6.656L10.44 24.08h-.593V40H1V13.005h9.155zm38.947 0v15.92h.706l5.998-13.091c1.165-2.605 3.647-2.83 3.647-2.83h8.56V40h-9.03V24.08h-.707l-5.88 13.09C51.23 39.765 48.63 40 48.63 40h-8.56V13.005h9.03zm40.532 18.953c4.38 0 8.336-2.556 9.596-6.125H71.573V40h8.588v-8.041h9.471z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="url(#paint0_linear_0_3749)"
                    d="M90.048 13.005H71.124c.45 6.01 5.627 11.155 10.986 11.155h17.716c1.023-4.995-2.497-11.155-9.778-11.155z"
                ></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_0_3749"
                    x1="100"
                    x2="71.124"
                    y1="14.444"
                    y2="14.444"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1F5CD7"></stop>
                    <stop offset="1" stopColor="#02AEFF"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default MirLogo