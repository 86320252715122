import React from 'react';
import {Link} from "react-scroll";

function PageFooter({currentStep}) {

    return (
        <footer className="bg-dark pt-1">
            <div className="container">
                {currentStep === 0 &&
                    <>
                        <hr className="mt-2 mb-0"/>
                        <div className="py-3">
                            <div className="container px-0">
                                <div
                                    className="d-md-flex justify-content-between align-items-center py-3 text-center text-md-left">
                                    <div className="text-muted text-primary-hover">©2021–2023 НСВЗ</div>
                                    <div className=" mt-3 mt-md-0">

                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item text-primary-hover">
                                                <Link className="nav-link" to="headerSection" smooth={true}>
                                                    Взять займ
                                                </Link>
                                            </li>
                                            <li className="list-inline-item text-primary-hover">
                                                <Link className="nav-link" to="paymentSection" smooth={true}>
                                                    Условия
                                                </Link>
                                            </li>
                                            <li className="list-inline-item text-primary-hover">
                                                <Link className="nav-link" to="recommendationsSection" smooth={true}>
                                                    Отзывы
                                                </Link>
                                            </li>
                                            <li className="list-inline-item text-primary-hover">
                                                <Link className="nav-link" to="FAQSection" smooth={true}>
                                                    Вопрос-ответ
                                                </Link>
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <div className="text-white-50 pt-4 pb-0">
                    <div className="row">
                        <div className='col-md-6'>
                            <p>
                                Займы от
                                партнеров выдаются в российских рублях, на расчетный счет, наличными или на карту,
                                гражданам РФ. Сервис не относится к финансовым учреждениям, не является кредитором или
                                банком и не несет ответственности за кредитные договоры, впоследствии заключенные
                                клиентами и партнерами. Доступные суммы для займа: от 1 000 рублей до 100 000 рублей.
                                Срок займа: от 62 дней, процентная ставка: от 0% до 365% годовых. Оставаясь на сайте,
                                а также совершая любые действия на сайте, вы принимаете условия
                                <a href="/user-agreement" target="_blank"> пользовательского соглашения</a>,
                                <a href="/public-offer-agreement" target="_blank"> договора публичной оферты</a>,
                                {/*<a href="/recurring-payments" target="_blank"> договора о рекуррентных платежах</a>,*/}
                                {/*<a href="/prices" target="_blank"> тарифах</a>,*/}
                                <a href="/privacy-policy" target="_blank"> политики обработки персональных данных</a>, а также даете
                                согласие на получение рекламных материалов.
                            </p>
                        </div>
                        <div className='col-md-6'>
                            <p>
                                По любым вопросам вы можете обращаться к специалистам сервиса по электронной почте
                                support@nsvz.ru.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="text-white-50 pt-3 pb-5">
                    <h5 className="text-white-50">Наши партнеры</h5>

                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                Екапуста ООО МКК «Русинтерфинанс» Лицензия ЦБ РФ: 2120754001243 Адрес:
                                630055, Новосибирская область, г. Новосибирск, ул. Гнесиных, д. 10/1, оф.
                                202
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                ЗАЙМЕР ООО МФК «Займер» Лицензия ЦБ РФ: 651303532004088 Адрес: 630099,
                                Новосибирская область, г. Новосибирск, ул. Октябрьская Магистраль, д. 3, оф.
                                906
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                Moneza ООО МКК «МАКРО» Лицензия ЦБ РФ: 001603045007582 Адрес: 115114, г.
                                Москва, ул. Летниковская, д. 10, стр. 4, пом. I, комн. 15
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Creditter ООО МКК "Кредиттер" Лицензия ЦБ РФ: 1903045009373 115280, г.
                                Москва, ул. Ленинская слобода, д. 19, строение 6, этаж 2, ком. 7
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                Money Man ООО МФК "Мани Мен" Лицензия ЦБ РФ: 2110177000478 Адрес: 121096, г.
                                Москва, ул. Василисы Кожиной, д. 1, оф. Д13
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Joy Money ООО МФК "Джой Мани" Лицензия ЦБ РФ: 651403550005450 Адрес: 123100,
                                г. Москва, Пресненская наб., дом 6, строение 2, южное лобби, 36 этаж, офис
                                3630
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                OneClickMoney ООО "МИКРОКРЕДИТНАЯ КОМПАНИЯ УНИВЕРСАЛЬНОГО ФИНАНСИРОВАНИЯ"
                                Лицензия ЦБ РФ: 001503760007126 Адрес: 344101, Ростовская область, г.
                                Ростов-на-Дону, ул. Ленинградская, дом 7 кабинет 11
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Ezaem ООО МФК «Веритас» Лицензия ЦБ РФ: 651303045003161 Адрес: 115114, г.
                                Москва, Улица Летниковская, дом 10c4, пом. I, часть ком. 15
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                Turbozaim ООО МФК «Турбозайм» Лицензия ЦБ РФ: 651303045003951 Адрес: 123290,
                                г.Москва, Магистральный 1-й туп., д.11, стр.10, пом.1, эт.1
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Webbankir ООО МФК «ВЭББАНКИР» Лицензия ЦБ РФ: 2120177002077 Адрес: 125466,
                                г. Москва, ул. Соколово-Мещерская, д. 29, оф. 308
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                ГлавФинанс ООО МКК Главная Финансовая Компания Лицензия ЦБ РФ: 2120323001658
                                Адрес: 350000, Краснодарский край, г. Краснодар, ул. Коммунаров, д. 76, оф.
                                365/4
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Lime ООО МФК «Лайм-Займ» Лицензия ЦБ РФ: 651303045004102 Адрес: 630102,
                                Россия, Новосибирск ул. Кирова 48, офис 1401
                            </p>

                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                ВебЗайм ООО МКК «Академическая» Лицензия ЦБ РФ: 19/035/50/009325 Адрес:
                                630091, г. Новосибирск, ул. Державина, д. 28, офис 301
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                До зарплаты ООО МФК "ДЗП-Центр" Лицензия ЦБ РФ: 651403140005467 Адрес:
                                190031, г. Санкт-Петербург, пер. Спасский, д. 14/35, лит. А, пом. 38 Н.
                            </p>

                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                Zaymigo ООО «Займиго МФК» Лицензия ЦБ РФ: 651303322004222 Адрес: 603093,
                                Нижегородская область, г. Нижний Новгород, ул. Ковровская, д. 21А, пом. 603
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                GreenMoney ООО МКК "СФ" Лицензия ЦБ РФ: 1903045009345 650000, Кемеровская
                                область, Кемерово, улица 50 лет Октября, дом 11
                            </p>
                        </div>
                    </div>


                </div>

            </div>


        </footer>
    )
}

export default PageFooter