function YandexLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="54"
            fill="none"
            viewBox="0 0 100 54"
        >
            <g fillRule="evenodd" clipRule="evenodd">
                <path
                    fill="#FFCF01"
                    d="M30 26.786c0-3.032.254-4.956 6.523-9.644C41.713 13.262 58.35 0 58.35 0v22.138H71V54H33.952C31.779 54 30 52.218 30 50.041V26.787z"
                ></path>
                <path
                    fill="#D3AC06"
                    d="M58.349 22.138v12.827L35.102 50.829l29.392-9.58V22.139h-6.145z"
                ></path>
                <path
                    fill="#231F20"
                    d="M47.726 21.474c1.36-1.622 3.345-2.195 4.435-1.279 1.09.917.872 2.975-.488 4.598-1.358 1.622-3.344 2.194-4.434 1.278-1.09-.916-.872-2.974.487-4.597z"
                ></path>
            </g>
        </svg>
    )
}

export default YandexLogo