export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function handleUserId() {
    let userId
    if (localStorage.getItem('current_step')) {
        userId = localStorage.getItem('current_step')
    } else {
        userId = uuidv4()
        localStorage.setItem('user_id', userId)
    }
    return userId
}

export function getNoun(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

export function handleOffersFilter() {
    // TODO  else if (offersFilter && !offersFilter.filter_data && partnerDataFilter) - сейчас работает неправильно,
    // TODO  нужно сделать так, чтобы была проверка, !offersFilter.filter_data но при этом чтобы не ломалось из-за undefined вoffersFilter
    let requestURL = `get_user_offers`
    let offersFilter = JSON.parse(localStorage.getItem('query_params'))
    let partnerDataFilter = JSON.parse(localStorage.getItem('partner_data'))
    if (offersFilter && offersFilter.filter_data) {
        offersFilter = offersFilter.filter_data.replace(/\|/g, '&');
        requestURL = `get_user_offers?${offersFilter}`
    } else if (!offersFilter || (!offersFilter.filter_data && partnerDataFilter)) {
        requestURL = `get_user_offers?filters[$and][0][traffic_types][slug][$eq]=${partnerDataFilter.traffic_type}`
    }
    return requestURL
}

export function AddedAdsFin() {
  const script = document.createElement('script')
  script.src = 'https://network.adsfin.pro/59918a10.js'
  script.async = true
  document.head.appendChild(script)
  return () => {
    document.head.removeChild(script)
  }
}
