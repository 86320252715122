import React, {useState} from 'react';
import {Controller} from "react-hook-form";
import InputMask from "react-input-mask";
import APIConfig from "../utils/api";

function FormStepOne({errors, register, control}) {
    const [phoneError, setPhoneError] = useState(false)
    const checkPhoneNumber = (phone) => {
        let phoneNumber = phone.target.value.replace(' (', '').replace(') ', '').replace('-', '').replace('_', '').replace(' ', '')
        phoneNumber = parseInt(phoneNumber)
        if (phoneNumber.toString().length === 11) {
            APIConfig.post(`check_phone?phone=${phoneNumber.toString()}`)
                .then(res => {

                    if (res.data.status !== 0) {
                        setPhoneError(true)
                        window.ym(85181272, 'reachGoal', `phone_format_error`)
                    } else {
                        setPhoneError(false)
                    }
                })
        }
    }
    return (
        <>
            <div className="col-12">
                <label className="form-label application-form-label">Фамилия</label>
                <input type="text"
                       className="form-control" {...register("lastName", {required: 'обязательное поле'})}/>
                {errors.lastName &&
                    <span className='validation__error'>{errors.lastName.message}</span>}
            </div>

            <div className="col-12">
                <label className="form-label application-form-label">Имя</label>
                <input type="text"
                       className="form-control" {...register("firstName", {required: 'обязательное поле'})}/>
                {errors.firstName &&
                    <span className='validation__error'>{errors.firstName.message}</span>}
            </div>

            <div className="col-12">
                <label className="form-label application-form-label">Отчество</label>
                <input type="text"
                       className="form-control" {...register("middleName", {required: 'обязательное поле'})}/>
                {errors.middleName &&
                    <span className='validation__error'>{errors.middleName.message}</span>}
            </div>

            <div className="col-12">
                <span className="form-label__span">Дата рождения</span>
            </div>
            <div className="col-3 from-label__div">
                <label className="form-label application-form-label">День</label>
                <input type="text" maxLength={2}
                       className="form-control" {...register("birthDay", {
                    required: 'обязательное поле',
                    maxLength: {value: 2, message: 'максимум 2 символа'}
                })}/>
                {errors.birthDay &&
                    <span className='validation__error'>{errors.birthDay.message}</span>}
            </div>
            <div className="col-3 from-label__div">
                <label className="form-label application-form-label">Месяц</label>
                <input type="text" maxLength={2}
                       className="form-control" {...register("birthMonth", {
                    required: 'обязательное поле',
                    maxLength: {value: 2, message: 'максимум 2 символа'}
                })}/>
                {errors.birthMonth &&
                    <span className='validation__error'>{errors.birthMonth.message}</span>}
            </div>
            <div className="col-6 from-label__div">
                <label className="form-label application-form-label">Год</label>
                <input type="text" maxLength={4} minLength={4}
                       className="form-control" {...register("birthYear", {
                    required: 'обязательное поле',
                    maxLength: {value: 4, message: 'максимум 4 символа'}
                })}/>
                {errors.birthYear &&
                    <span className='validation__error'>{errors.birthYear.message}</span>}

            </div>

            <div className="col-12">
                <label className="form-label application-form-label">Номер телефона</label>

                <Controller
                    as={InputMask}
                    render={
                        ({field}) =>
                            <InputMask mask="7 (999) 999 99-99" type="tel"
                                       autoComplete="new-password"
                                       onInput={e => checkPhoneNumber(e)}
                                       className="form-control" {...field}/>
                    }
                    control={control}
                    defaultValue=""
                    rules={{required: 'обязательное поле'}}
                    name="phoneNumber"

                />

                {phoneError && <span className='validation__error'>некорректный номер телефона</span>}
                {errors.phoneNumber &&
                    <span className='validation__error'>
                        {errors.phoneNumber.type === 'alreadyExistPhone'
                            ? <span>Номер телефона уже используется, перейдите
                                на <a href="/login">страницу входа</a> для авторизации
                            </span>
                            : errors.phoneNumber.message
                        }

                    </span>}
            </div>

            <div className="col-12">
                <label className="form-label application-form-label">Email</label>
                <input type="text"
                       className="form-control" {...register("email", {required: 'обязательное поле'})}/>
                {errors.email &&
                    <span className='validation__error'>{errors.email.message}</span>}
            </div>
            <div className="col-12 terms-accept">
                <input type="checkbox" className="form-check-input"
                       id="personalAccept" {...register("personalAccept",
                    {required: 'необходимо согласиться с условиями'})}/>
                <label className="form-check-label" htmlFor="personalAccept">
                    Я ознакомлен и согласен со <a href="/privacy-policy" target="_blank"> следующим соглашением </a>
                </label>
                {errors.personalAccept &&
                    <span className='validation__error'>{errors.personalAccept.message}</span>}
            </div>
            {/*<div className="col-12 terms-accept">*/}
            {/*    <input type="checkbox" className="form-check-input"*/}
            {/*           id="paymentAccept" {...register("paymentAccept",*/}
            {/*        {required: 'необходимо согласиться с условиями'})}/>*/}
            {/*    <label className="form-check-label" htmlFor="paymentAccept">*/}
            {/*        Я даю согласие на <a href="/recurring-payments" target="_blank">рекуррентные списания</a>*/}
            {/*    </label>*/}
            {/*    {errors.paymentAccept &&*/}
            {/*        <span className='validation__error'>{errors.paymentAccept.message}</span>}*/}
            {/*</div>*/}
        </>
    )
}

export default FormStepOne