import React, {useState, useEffect} from 'react';
import axios from "axios";


function AddressData({errors, register, control, setValue}) {
    const [cityFiasId, setCityFiasId] = useState(null)
    const [focusedInput, setFocusedInput] = useState(null)
    const [suggestionsList, setSuggestionsList] = useState(null)
    const [sameAddress, setSameAddress] = useState(true)
    const handleRegionQuery = (query) => {
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token 202cda6bfc04720d69e386e1a91c8104f06265d6"
        }
        const data = {
            'query': query,

        }
        if (cityFiasId) {
            data['locations'] = [{"city_fias_id": cityFiasId}]
        }
        axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
            data, {headers: headers})
            .then(r => {
                setSuggestionsList(r.data.suggestions)
            })
    }
    const handleInputValue = (e) => {
        console.log(e.target.value.length)
        if (e.target.value.length) {
            handleRegionQuery(e.target.value)
        } else {
            setSuggestionsList(null)
        }
    }

    const handleSetValue = (fieldName, value, fiasId = null) => {
        if ('fullRegionNameRegistration' === fieldName) {
            localStorage.setItem('fullRegionNameRegistration', JSON.stringify(value.data))
        }
        if ('fullRegionNameFact' === fieldName) {
            localStorage.setItem('fullRegionNameFact', JSON.stringify(value.data))
        }
        setValue(fieldName, value.unrestricted_value, {shouldValidate: true})

        if (sameAddress) {
            setValue('fullRegionNameFact', value.unrestricted_value, {shouldValidate: true})
            localStorage.setItem('fullRegionNameFact', JSON.stringify(value.data))
        }
        setSuggestionsList(null)
    }

    useEffect(() => {
        if (!sameAddress) {
            setValue('fullRegionNameFact', null, {shouldValidate: true})
            localStorage.removeItem('fullRegionNameFact')
        }
        if (sameAddress) {
            const fullRegionNameFact = JSON.parse(localStorage.getItem('fullRegionNameRegistration'))
            setValue('fullRegionNameFact', fullRegionNameFact, {shouldValidate: true})
            localStorage.setItem('fullRegionNameFact', JSON.stringify(fullRegionNameFact))
        }
    }, [sameAddress])

    return (
        <>
            <h5>Адрес регистрации</h5>


            <div className="full-suggestion__wrapper">
                <span>Начните вводить свой адрес регистрации</span>
                <input type="text" onInput={e => handleInputValue(e)}
                       onFocus={() => setFocusedInput('fullRegionNameRegistration')}
                       autoComplete="new-password"
                       className={`${suggestionsList ? 'with-suggestions' : ''} form-control`} {...register("fullRegionNameRegistration", {required: 'обязательное поле'})}/>
                {suggestionsList && focusedInput === 'fullRegionNameRegistration' &&
                    <div className='suggestions__wrapper'>
                        <ul className="list-group">
                            <li className="list-group-item disabled-list-item">Выберите вариант или продолжайте ввод
                            </li>
                            {suggestionsList.slice(0, 5).map(i => {
                                return <li key={i.data.fias_id} className="list-group-item"
                                           onClick={() => {
                                               handleSetValue('fullRegionNameRegistration', i)
                                           }}>
                                    {i.value}</li>
                            })}
                        </ul>
                    </div>
                }
                {errors.fullRegionNameRegistration &&
                    <span className='validation__error'>{errors.fullRegionNameRegistration.message}</span>}
            </div>

            <div className={`${sameAddress ? 'same-address-checker_checked' : 'same-address-checker'}`}
                 onClick={() => setSameAddress(!sameAddress)}>
                <span className='same-address-checker__label'>Адрес регистрации совпадает с фактическим</span>
            </div>

            {!sameAddress &&
                <>
                    <h5 className='mt-3 mb-0'>Фактический адрес</h5>
                    <div className="full-suggestion__wrapper ">
                        <span>Начните вводить свой адрес регистрации</span>
                        <input type="text" onInput={e => handleInputValue(e)}
                               onFocus={() => setFocusedInput('fullRegionNameFact')}
                               autoComplete="new-password"
                               className={`${suggestionsList ? 'with-suggestions' : ''} form-control`} {...register("fullRegionNameFact", {required: 'обязательное поле'})}/>
                        {suggestionsList && focusedInput === 'fullRegionNameFact' &&
                            <div className='suggestions__wrapper'>
                                <ul className="list-group">
                                    <li className="list-group-item disabled-list-item">Выберите вариант или продолжайте
                                        ввод
                                    </li>
                                    {suggestionsList.slice(0, 5).map(i => {
                                        return <li key={i.data.fias_id} className="list-group-item"
                                                   onClick={() => {
                                                       handleSetValue('fullRegionNameFact', i)
                                                   }}>
                                            {i.value}</li>
                                    })}
                                </ul>
                            </div>
                        }
                        {errors.fullRegionNameRegistration &&
                            <span className='validation__error'>{errors.fullRegionNameRegistration.message}</span>}
                    </div>
                </>
            }

        </>
    )
}

export default AddressData