import React, {useState, useEffect} from 'react';
import APIConfig from "../utils/api";

function FormStepTwo({errors, register, control, setValue}) {

    const [timeLeft, setTimeLeft] = useState(91);

    useEffect(() => {
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);


    const getUserCode = () => {
        let phoneNumber = JSON.parse(localStorage.getItem('step_2')).phoneNumber
        phoneNumber = phoneNumber.replace(' (', '').replace(') ', '').replace('-', '').replace('_', '').replace(' ', '')
        APIConfig.get(`request_auth_code?phone=${phoneNumber}&user_id=${localStorage.getItem('user_id')}`).then(res => {
            console.log(res)
            setTimeLeft(91)
        })
    }

    useEffect(() => {
        getUserCode()
    }, [])

    return (
        <>
            <div className="col-12">
                <h5 style={{marginTop: '-30px', fontSize: 18}}>Мы отправили код подтверждения на указанный вами
                    номер</h5>
                <label className="form-label application-form-label">Код из СМС</label>
                <input type="text"
                       className="form-control" {...register("smsCode", {required: 'обязательное поле'})}/>
                {errors.smsCode &&
                    <span className='validation__error'>{errors.smsCode.message}</span>}
                {timeLeft > 0 ?
                    <span>Повторная отправка кода возможна через {timeLeft} секунд</span>
                    : <span className='request-new-code' onClick={() => getUserCode()}>запросить новый код</span>
                }
            </div>

        </>
    )
}

export default FormStepTwo