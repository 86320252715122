import React, {useState} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading, AccordionItemPanel,
    AccordionItemState
} from "react-accessible-accordion";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

function FAQSection({handleLoanInit}) {
    return (
        <section className="bg-dark position-relative" id="FAQSection" >
            <figure className="position-absolute bottom-0 start-0 d-none d-lg-block">
                <svg width="822.2px" height="301.9px" viewBox="0 0 822.2 301.9">
                    <path
                        className="fill-warning"
                        d="M752.5,51.9c-4.5,3.9-8.9,7.8-13.4,11.8c-51.5,45.3-104.8,92.2-171.7,101.4c-39.9,5.5-80.2-3.4-119.2-12.1 c-32.3-7.2-65.6-14.6-98.9-13.9c-66.5,1.3-128.9,35.2-175.7,64.6c-11.9,7.5-23.9,15.3-35.5,22.8c-40.5,26.4-82.5,53.8-128.4,70.7 c-2.1,0.8-4.2,1.5-6.2,2.2L0,301.9c3.3-1.1,6.7-2.3,10.2-3.5c46.1-17,88.1-44.4,128.7-70.9c11.6-7.6,23.6-15.4,35.4-22.8 c46.7-29.3,108.9-63.1,175.1-64.4c33.1-0.6,66.4,6.8,98.6,13.9c39.1,8.7,79.6,17.7,119.7,12.1C634.8,157,688.3,110,740,64.6 c4.5-3.9,9-7.9,13.4-11.8C773.8,35,797,16.4,822.2,1l-0.7-1C796.2,15.4,773,34,752.5,51.9z"
                    />
                </svg>
            </figure>
            <figure className="position-absolute top-0 end-0">
                <svg width="822.2px" height="301.9px" viewBox="0 0 822.2 301.9">
                    <path
                        className="fill-primary"
                        d="M752.5,51.9c-4.5,3.9-8.9,7.8-13.4,11.8c-51.5,45.3-104.8,92.2-171.7,101.4c-39.9,5.5-80.2-3.4-119.2-12.1 c-32.3-7.2-65.6-14.6-98.9-13.9c-66.5,1.3-128.9,35.2-175.7,64.6c-11.9,7.5-23.9,15.3-35.5,22.8c-40.5,26.4-82.5,53.8-128.4,70.7 c-2.1,0.8-4.2,1.5-6.2,2.2L0,301.9c3.3-1.1,6.7-2.3,10.2-3.5c46.1-17,88.1-44.4,128.7-70.9c11.6-7.6,23.6-15.4,35.4-22.8 c46.7-29.3,108.9-63.1,175.1-64.4c33.1-0.6,66.4,6.8,98.6,13.9c39.1,8.7,79.6,17.7,119.7,12.1C634.8,157,688.3,110,740,64.6 c4.5-3.9,9-7.9,13.4-11.8C773.8,35,797,16.4,822.2,1l-0.7-1C796.2,15.4,773,34,752.5,51.9z"
                    />
                </svg>
            </figure>
            <figure className="position-absolute bottom-0 start-50 translate-middle-x ms-n9 mb-5">
                <svg width="23px" height="23px">
                    <path
                        className="fill-primary"
                        d="M23.003,11.501 C23.003,17.854 17.853,23.003 11.501,23.003 C5.149,23.003 -0.001,17.854 -0.001,11.501 C-0.001,5.149 5.149,-0.000 11.501,-0.000 C17.853,-0.000 23.003,5.149 23.003,11.501 Z"
                    />
                </svg>
            </figure>
            <figure className="position-absolute bottom-0 end-0 me-5 mb-5">
                <svg width="22px" height="22px">
                    <path
                        className="fill-warning"
                        d="M22.003,11.001 C22.003,17.078 17.077,22.003 11.001,22.003 C4.925,22.003 -0.001,17.078 -0.001,11.001 C-0.001,4.925 4.925,-0.000 11.001,-0.000 C17.077,-0.000 22.003,4.925 22.003,11.001 Z"
                    />
                </svg>
            </figure>
            <div className="container position-relative">
                <div className="row justify-content-between align-items-center my-3 text-center">
                    <h2 className="h1 text-white">
                        Вопрос-ответ
                    </h2>
                    <div className="col-lg-12 mt-4 accordion__wrapper">

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <h5>Какие документы нужны для оформления займа?</h5>
                                        <AccordionItemState>
                                            {(state) =>
                                                <div>
                                                    {state.expanded
                                                        ? <MdKeyboardArrowDown size={36} color={'#24292d'}/>
                                                        : <MdKeyboardArrowUp size={36} color={'#24292d'}/>}
                                                </div>
                                            }
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ul className='list-inline'>
                                        <li>— Паспорт гражданина РФ;</li>
                                        <li>— СНИЛС (по желанию);</li>
                                        <li>— Личная именная дебетовая банковская карта.</li>
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <h5>Сколько времени будет рассматриваться моя заявка?</h5>
                                        <AccordionItemState>
                                            {(state) =>
                                                <div>
                                                    {state.expanded
                                                        ? <MdKeyboardArrowDown size={36} color={'#24292d'}/>
                                                        : <MdKeyboardArrowUp size={36} color={'#24292d'}/>}
                                                </div>
                                            }
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Если ваша заявка заполнена правильно, вы получите решение в течение
                                        пары минут.</p>
                                    <p>После подписания договора деньги моментально поступят на вашу карту.
                                        Не нужно ехать в офис или выходить из дома!</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <h5>Когда можно получить деньги после одобрения заявки?</h5>
                                        <AccordionItemState>
                                            {(state) =>
                                                <div>
                                                    {state.expanded
                                                        ? <MdKeyboardArrowDown size={36} color={'#24292d'}/>
                                                        : <MdKeyboardArrowUp size={36} color={'#24292d'}/>}
                                                </div>
                                            }
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Обычно средства поступают моментально. Однако если вы используете банковский
                                        расчетный счет, это может занять от 1 до 3 рабочих дней.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <h5>Какие условия получения займа?</h5>
                                        <AccordionItemState>
                                            {(state) =>
                                                <div>
                                                    {state.expanded
                                                        ? <MdKeyboardArrowDown size={36} color={'#24292d'}/>
                                                        : <MdKeyboardArrowUp size={36} color={'#24292d'}/>}
                                                </div>
                                            }
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ul className='list-inline'>
                                        <li>— Являетесь гражданином Российской Федерации;</li>
                                        <li>— Зарегистрированы на территории Российской Федерации;</li>
                                        <li>— Ваш возраст 18–65 лет;</li>
                                        <li>— Имеете ежемесячный доход</li>
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <h5>Как погасить полученный займ?</h5>
                                        <AccordionItemState>
                                            {(state) =>
                                                <div>
                                                    {state.expanded
                                                        ? <MdKeyboardArrowDown size={36} color={'#24292d'}/>
                                                        : <MdKeyboardArrowUp size={36} color={'#24292d'}/>}
                                                </div>
                                            }
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Вы можете погасить займ – наличными, банковской картой, банковским переводом
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>

                        <button onClick={() => handleLoanInit()} className={'btn btn-lg mt-3 btn-primary form-control'}>Получить займ</button>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQSection