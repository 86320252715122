import React, {useState} from 'react';
import {Range, getTrackBackground} from 'react-range';
import {uuidv4, getNoun} from "../utils";



function SlidersBlock({handleLoanInit}) {
    const userId = uuidv4()
    const [loanValues, setLoanValues] = useState([10000]);
    const [periodValues, setPeriodValues] = useState([30]);
    const [minMaxSum, setMinMaxSum] = useState([1000, 50000]);
    const [minMaxPeriod, setMinMaxPeriod] = useState([10, 90]);

    return (
        <div className='slider-blocks__wrapper'>
            <div className='slider-block__wrapper mb-3'>
                <h6 className='mt-4'>Сумма займа</h6>
                <div className='slider__wrapper'>
                    <span className='label-amount'>{minMaxSum[0].toLocaleString()}</span>


                    <Range
                        values={loanValues}
                        step={500}
                        min={minMaxSum[0]}
                        max={minMaxSum[1]}
                        rtl={false}
                        onChange={(loanValues) => {
                            setLoanValues(loanValues)
                            localStorage.setItem('loan_amount', loanValues[0])
                        }}
                        renderTrack={({props, children}) => (
                            <div className='range-slider__wrapper'

                                 style={{
                                     ...props.style,
                                     height: '10px',
                                     borderRadius: 3,
                                     width: '100%',
                                     backgroundColor: '#ccc',
                                 }}
                            >
                                <div
                                    ref={props.ref}
                                    style={{
                                        height: '10px',
                                        width: '100%',
                                        borderRadius: '4px',
                                        background: getTrackBackground({
                                            values: loanValues,
                                            colors: ['#fd564d', '#ccc'],
                                            min: minMaxSum[0],
                                            max: minMaxSum[1],
                                            rtl: false
                                        }),
                                        alignSelf: 'center'
                                    }}
                                >
                                    {children}
                                </div>
                            </div>
                        )}
                        renderThumb={({props, isDragged}) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '36px',
                                    width: '36px',
                                    borderRadius: '44px',
                                    backgroundColor: '#FFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '0px 1px 2px #AAA'
                                }}
                            >
                                <div
                                    style={{
                                        height: '16px',
                                        width: '5px',
                                        backgroundColor: isDragged ? '#fd564d' : '#CCC'
                                    }}
                                />

                            </div>
                        )}
                    />
                    <span className='label-amount'>{minMaxSum[1].toLocaleString()}</span>
                </div>
            </div>

            <div className='slider-block__wrapper mt-3'>
                <h6 className='mt-4'>Срок займа <span className='small-text'>(дней)</span></h6>
                <div className='slider__wrapper'>
                    <span className='label-amount'>{minMaxPeriod[0].toLocaleString()}</span>


                    <Range
                        values={periodValues}
                        step={1}
                        min={minMaxPeriod[0]}
                        max={minMaxPeriod[1]}
                        rtl={false}
                        onChange={(periodValues) => {
                            setPeriodValues(periodValues)
                            localStorage.setItem('loan_period', periodValues[0])

                        }}
                        renderTrack={({props, children}) => (
                            <div className='range-slider__wrapper'

                                 style={{
                                     ...props.style,
                                     height: '10px',
                                     borderRadius: 3,
                                     width: '100%',
                                     backgroundColor: '#ccc',
                                 }}
                            >
                                <div
                                    ref={props.ref}
                                    style={{
                                        height: '10px',
                                        width: '100%',
                                        borderRadius: '4px',
                                        background: getTrackBackground({
                                            values: periodValues,
                                            colors: ['#fd564d', '#ccc'],
                                            min: minMaxPeriod[0],
                                            max: minMaxPeriod[1],
                                            rtl: false
                                        }),
                                        alignSelf: 'center'
                                    }}
                                >
                                    {children}
                                </div>
                            </div>
                        )}
                        renderThumb={({props, isDragged}) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '36px',
                                    width: '36px',
                                    borderRadius: '44px',
                                    backgroundColor: '#FFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '0px 1px 2px #AAA'
                                }}
                            >
                                <div
                                    style={{
                                        height: '16px',
                                        width: '5px',
                                        backgroundColor: isDragged ? '#fd564d' : '#CCC'
                                    }}
                                />

                            </div>
                        )}
                    />
                    <span className='label-amount'>{minMaxPeriod[1].toLocaleString()}</span>
                </div>
            </div>

            <h5 className='mt-4 mb-3'>Займ на сумму <strong>{loanValues[0].toLocaleString()}₽ </strong>
                сроком <strong>{periodValues[0].toLocaleString()}
                    {getNoun(periodValues[0], ' день', ' дня', ' дней')}</strong>
            </h5>

            <a href={`/form`} target={'_blank'} rel={'noreferrer'}
               onClick={() => handleLoanInit(userId)}
               className={'btn btn-lg form-control btn-danger-soft'}>Получить деньги</a>
        </div>
    )

}

export default SlidersBlock