import React, {useState, useEffect} from 'react';
import APIConfig from "../utils/api";
import InputMask from 'react-input-mask';
import PageHeader from "./PageHeader";

function OfferRedirect() {
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [authCode, setAuthCode] = useState(null)
    const [initedCode, setInitedCode] = useState(false)

    const handlePhoneNumber = e => {
        let phoneNumber = e.target.value.replace(' (', '').replace(') ', '').replace('-', '').replace('_', '').replace(' ', '').replace('+', '')
        phoneNumber = parseInt(phoneNumber)
        if (phoneNumber.toString().length === 11) {
            setPhoneNumber(phoneNumber)
        } else {
            setPhoneNumber(null)
        }
    }
    const handleAuthCode = e => {
        let code = e.target.value.replace(' (', '').replace(') ', '').replace('-', '').replace('_', '').replace(' ', '').replace('+', '')
        code = parseInt(code)
        if (code.toString().length === 4) {
            setAuthCode(code)
        } else {
            setAuthCode(null)
        }
    }

    const getAuthCode = () => {
        APIConfig.get(`request_auth_code?phone=${phoneNumber}`).then(res => {
            console.log(res)
            setTimeout(() => {
                setInitedCode(true)
            }, 100)
        })
    }

    const doLogIn = () => {
        const data = {
            "phone": phoneNumber,
            "code": authCode
        }
        APIConfig.post(`login`, data)
            .then(res => {
                localStorage.setItem('access_token', res.data.access_token)
                localStorage.setItem('user_id', res.data.uid)
                window.location.replace(`/profile/${res.data.uid}`)
            })
            .catch(err => {
                if (err.response.status === 401) {
                    console.log('wrong pass')
                }
            })
    }


    return (
        <>
            <PageHeader currentStep={4}/>
            <section className='position-relative h-100 overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                <div className='container'>
                    <div className='row align-items-center g-5'>

                        <h3 className='text-center'>Личный кабинет закрыт</h3>
                        <div className='col-lg-12 position-relative z-index-1 form__wrapper mb-5 mb-sm-0'>
                            <div className="bg-white rounded-3 p-4 p-sm-5 form__flex">
                                <div className="col-12">
                                    <p>
                                        Мы хотим обратить ваше внимание на то, что личный кабинет в нашем сервисе больше
                                        не
                                        доступен. В связи с этим, все активные подписки пользователей были отменены.
                                    </p>
                                    <p>
                                        Если у вас возникли вопросы или проблемы, связанные с использованием сервиса,
                                        пожалуйста, не стесняйтесь обращаться
                                        к нашей службе поддержки по адресу электронной почты: support@nsvz.ru.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/*<h3 className='text-center'>Войти в личный кабинет</h3>*/}
                        {/*<div className='col-lg-12 position-relative z-index-1 form__wrapper mb-5 mb-sm-0'>*/}
                        {/*    <div className="bg-white rounded-3 p-4 p-sm-5 form__flex">*/}
                        {/*        <div className="col-12">*/}
                        {/*            <label className="form-label application-form-label">Номер телефона</label>*/}
                        {/*            <InputMask className="form-control form-control-lg phone-mask__item"*/}
                        {/*                       mask="+7 (999) 999 99-99"*/}
                        {/*                       type="tel" disableUnderline maskChar="_"*/}
                        {/*                       onChange={e => handlePhoneNumber(e)}*/}
                        {/*            />*/}

                        {/*            {initedCode &&*/}
                        {/*                <>*/}
                        {/*                    <label className="form-label application-form-label mt-3">Код из SMS</label>*/}
                        {/*                    <InputMask className="form-control form-control-lg phone-mask__item"*/}
                        {/*                               mask="9999"*/}
                        {/*                               type="tel" disableUnderline maskChar="_"*/}
                        {/*                               onChange={e => handleAuthCode(e)}*/}
                        {/*                    />*/}

                        {/*                    {authCode ?*/}
                        {/*                        <button className='form-control btn-lg btn-danger-soft mt-3'*/}
                        {/*                                onClick={() => doLogIn(phoneNumber)}>Войти*/}
                        {/*                        </button>*/}
                        {/*                        :*/}
                        {/*                        <button className='form-control btn-lg mt-3' disabled={true}>*/}
                        {/*                            Войти*/}
                        {/*                        </button>*/}
                        {/*                    }*/}
                        {/*                </>*/}
                        {/*            }*/}

                        {/*            {phoneNumber && !initedCode &&*/}
                        {/*                <button className='form-control btn-lg btn-danger-soft mt-3'*/}
                        {/*                        onClick={() => getAuthCode(phoneNumber)}>Получить пароль по*/}
                        {/*                    SMS*/}
                        {/*                </button>*/}
                        {/*            }*/}
                        {/*            {!phoneNumber && !initedCode &&*/}
                        {/*                <button className='form-control btn-lg  mt-3 disabled' disabled={true}>Получить*/}
                        {/*                    пароль по*/}
                        {/*                    SMS*/}
                        {/*                </button>*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        </>
    )
}

export default OfferRedirect;