import React, {useState} from 'react';
import SlidersBlock from "./SlidersBlock";

function StepperSection() {
    return (
        <section className='pb-5 pt-5 pt-lg-5'>
            <div className='container'>
                <div className='row mb-5'>
                    <div className="col-lg-8 mx-auto text-center">
                        <h2 className="fs-1">Как получить займ</h2>
                        <p className="mb-0">Следуй этим простым шагам и получи займ с шансом одобрения 99.9%</p>
                    </div>
                </div>
                <div className='row mt-5 mb-4'>
                    <div className="col-lg-4 mx-auto text-center mb-4 mb-md-0">
                        <img src={require('./../assets/images/apply.png')} width={86}/>
                        <h4 className='mt-2'>Заполнить простую анкету на нашем сайте</h4>
                    </div>
                    <div className="col-lg-4 mx-auto text-center mb-4 mb-md-0">
                        <img src={require('./../assets/images/selectmfo.png')} width={86}/>
                        <h4 className='mt-2'>Подать заявки в отобранные МФО</h4>
                    </div>
                    <div className="col-lg-4 mx-auto text-center mb-4 mb-md-0">
                        <img src={require('./../assets/images/payment.png')} width={86}/>
                        <h4 className='mt-2'>Получить деньги одним из удобных способов</h4>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StepperSection