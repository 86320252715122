import React, {useEffect} from 'react';

function SubscribeRedirectNoPayment() {
    useEffect(() => {
        window.location.replace('/offers')
    }, [])
    return (
        <></>
    )
}

export default SubscribeRedirectNoPayment