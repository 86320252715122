import React, {useState, useEffect} from 'react';
import AdvCardItemNew from "./AdvCardItemNew";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import APIConfig from "../utils/api";
import {uuidv4, handleOffersFilter, AddedAdsFin} from "../utils";
import CustomAd from "./CustomAds";
import axios from "axios";
import AdsFinBanner from "./AdsFinBanner";
function handleLinkParams() {
    const params = new URLSearchParams(window.location.search)
    const paramsDict = {}
    for (const param of params) {
        paramsDict[param[0]] = param[1]
    }
    if (Object.keys(paramsDict).length) {
        localStorage.setItem('query_params', JSON.stringify(paramsDict))
    }
}

function OffersListSectionNew({cardType = Math.random()}) {
    const [showPreloader, setShowPreloader] = useState(true)
    const [selectedOffer, setSelectedOffer] = useState(null)
    const [offersList, setOffersList] = useState(null)
    const [offerUrlParams, setOfferUrlParams] = useState(null)
    const [secondBanner, setSecondBanner] = useState(false)

    const [bannerSize, setBannerSize] = useState("970")
    useEffect(() => {
        if (window.innerWidth < 969) {
            setBannerSize('300')
        }
    }, [])

    function createLeadID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    async function handleLeadID() {
        let leadID = localStorage.getItem('leadID') || null
        if (leadID) {
            return leadID
        } else {
            leadID = createLeadID()
            localStorage.setItem('leadID', leadID)
            return leadID
        }
    }

    function stringifyFilteredQueryParameters() {
        const queryParamsObject = JSON.parse(localStorage.getItem('query_params') || '{}');
        const filteredParams = {};

        for (const key in queryParamsObject) {
            if (key.includes('aff_sub') || key === 'sub') {
                filteredParams[key] = queryParamsObject[key];
            }
        }

        const filteredParamsString = Object.entries(filteredParams)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        return filteredParamsString;
    }

    function handleOfferClick(url, offerID, position = 0, platform = 'direct') {
        let URLParams = localStorage.getItem('query_params');
        console.log('URLParams', URLParams)
        let linkExtra = null
        if (URLParams) {
            linkExtra = stringifyFilteredQueryParameters(URLParams)
        }
        console.log(url, offerID, position, platform, URLParams, linkExtra)


        handleLeadID().then(res => {
            if (linkExtra) {
                console.log(linkExtra, 'linkExtra')
                linkExtra = `${linkExtra}&aff_sub5=${res}&sub5=${res}`
                localStorage.setItem('direction', `${url}&${linkExtra}`)
                localStorage.setItem('offerID', offerID)
                localStorage.setItem('position', position + 1)
                localStorage.setItem('platform', platform)
            } else {
                localStorage.setItem('direction', `${url}&sub5=${res}`)
                localStorage.setItem('offerID', offerID)
                localStorage.setItem('position', position + 1)
                localStorage.setItem('platform', platform)
            }
        })


    }

    const getSingleOffer = () => {
        axios.get('https://loanbot-api.ldtrk.ru/api/v1/get_offers_lg?tkn=ii88xxxxxxxxxxxxxxxxxxx').then(res => {
            const approvedOffers = res.data.data.filter(offer => offer.affiliate_status === "approved");
            const sortedData = approvedOffers.sort((a, b) => {
                return b.metrics_detailed["7days"].epc - a.metrics_detailed["7days"].epc;
            });
            const topFive = sortedData.slice(0, 10);
            const randomIndex = Math.floor(Math.random() * topFive.length);
            const randomSelection = topFive[randomIndex];
            setSelectedOffer(randomSelection)
            const URL = `https://go.leadgid.ru/aff_c?aff_id=35153&offer_id=${randomSelection.legacy_id}`
            console.log('URL', URL)
            setShowPreloader(false)
        })
    }

    useEffect(() => {
        const OFFERS_LIST_ID = uuidv4()
        localStorage.setItem('offers_list_id', OFFERS_LIST_ID)
        if (localStorage.getItem('user_id') === null) {
            localStorage.setItem('user_id', uuidv4())
            localStorage.setItem('inited_from_offers_list', 'true')
        }
        handleLinkParams()

        setTimeout(() => {
            if (window.location.pathname === '/ofr') {
                setSecondBanner(true)
            }
            getSingleOffer()
        }, 1000)

    }, [])
    return (
        <>
            {showPreloader ?
                <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                    <p className='mt-3'>Подбираем персональные предложения...</p>
                </section>
                : <>
                    <PageHeader currentStep={4}/>
                    <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                        <div className='container'>
                            <div className='row mt-2 mb-5'>
                                <div className="col-lg-8 mx-auto text-center">
                                    <h2 className="fs-1">{selectedOffer.name.split(' -')[0]} - займ одобрен</h2>
                                    <p className="mb-0">
                                        Получите перевод от 30 000₽ онлайн!
                                    </p>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{margin: '-15px 0 30px 0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={`https://storage.yandexcloud.net/ezflow-bucket/mfo_logo/${selectedOffer.legacy_id}.png`}
                                         alt={selectedOffer.name.split(' -')[0]}/>
                                    </div>
                                    <a href={'/gon'} target='_blank' onClick={() =>
                                        handleOfferClick(
                                            `https://go.leadgid.ru/aff_c?aff_id=35153&offer_id=${selectedOffer.legacy_id}`,
                                            selectedOffer.legacy_id,
                                            1,
                                            'EMAIL'
                                        )} className="btn btn-lg btn-danger-soft form-control">
                                        Получить деньги
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='pb-5 pt-5 pt-lg-5'>
                        <div className='container'>
                            <div className='row mb-5'>
                                <div className="col-lg-8 mx-auto text-center">
                                    <h2 className="fs-1">Как получить займ</h2>
                                    <p className="mb-0">Следуй этим простым шагам и получи займ через 15 минут</p>
                                </div>
                            </div>
                            <div className='row mt-5 mb-4'>
                                <div className="col-lg-4 mx-auto text-center mb-4 mb-md-0">
                                    <img src={require('./../assets/images/apply.png')} width={90}/>
                                    <h4 className='mt-2'>Заполнить простую анкету на нашем сайте</h4>
                                </div>
                                <div className="col-lg-4 mx-auto text-center mb-4 mb-md-0">
                                    <img src={require('./../assets/images/selectmfo.png')} width={90}/>
                                    <h4 className='mt-2'>Дождаться положительного решения</h4>
                                </div>
                                <div className="col-lg-4 mx-auto text-center mb-4 mb-md-0">
                                    <img src={require('./../assets/images/payment.png')} width={90}/>
                                    <h4 className='mt-2'>Получить деньги одним из удобных способов</h4>
                                </div>
                            </div>

                            <div className='row mt-2 mb-2'>
                                <div className='col-md-12'>
                                    <a href={'/gon'} target='_blank' onClick={() =>
                                        handleOfferClick(
                                            `https://go.leadgid.ru/aff_c?aff_id=35153&offer_id=${selectedOffer.legacy_id}`,
                                            selectedOffer.legacy_id,
                                            1,
                                            'EMAIL'
                                        )} className="btn btn-lg btn-danger-soft form-control">
                                        Взять займ - сейчас
                                    </a>
                                </div>
                            </div>
                            <AdsFinBanner bannerId="131969538744324112" />
                        </div>
                    </section>
                    <PageFooter currentStep={4}/>
                </>
            }
        </>
    )
}

export default OffersListSectionNew