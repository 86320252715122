import React, {useState, useEffect} from 'react';
import AdvCardItem from "./AdvCardItem";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import APIConfig from "../utils/api";
import {uuidv4, handleOffersFilter, AddedAdsFin} from "../utils";


function handleLinkParams() {
    const params = new URLSearchParams(window.location.search)
    const paramsDict = {}
    for (const param of params) {
        paramsDict[param[0]] = param[1]
    }
    if (Object.keys(paramsDict).length) {
        localStorage.setItem('query_params', JSON.stringify(paramsDict))
    }
}


function OffersListSectionTinderLayout({offerType = 'arbitrage'}) {
    const [showPreloader, setShowPreloader] = useState(true)
    const [loadCard, setLoadCard] = useState(false)
    const [offersList, setOffersList] = useState([])
    const handleOfferImpressions = () => {
        return ({
            'user_id': localStorage.getItem('user_id'),
            'offer_id': offersList[0].id,
            'type': 'impression'
        })
    }

    const [bannerSize, setBannerSize] = useState('970')
    useEffect(() => {
        if (window.innerWidth < 969) {
            setBannerSize('300')
        }
    }, [])

    const getUserOffers = () => {
        const requestURL = handleOffersFilter()
        APIConfig.get(requestURL).then(res => {
            const randomOffers = res.data.offers_list.sort(function (a, b) {
                return 0.5 - Math.random()
            })
            setOffersList(randomOffers.slice(0, 10000))
        })
    }

    useEffect(() => {
        if (offersList.length) {
            APIConfig.post(`update_impressions?user_id=${localStorage.getItem('user_id')}`,
                handleOfferImpressions()).then(status => {
                setShowPreloader(false)
            })
        }
    }, [offersList])

    useEffect(() => {
        const OFFERS_LIST_ID = uuidv4()
        localStorage.setItem('offers_list_id', OFFERS_LIST_ID)
        if (localStorage.getItem('user_id') === null) {
            localStorage.setItem('user_id', uuidv4())
            localStorage.setItem('inited_from_offers_list', 'true')
        }
        handleLinkParams()

        setTimeout(() => {
            getUserOffers(OFFERS_LIST_ID)
        }, 500)

    }, [])

    const handleEmptyOffersList = () => {
        if (offersList.length <= 1) {
            window.ym(85181272, 'reachGoal', 'empty_offers_list')

            const userID = localStorage.getItem('user_id') || ''
            const payload = {
                "offer_id": '999999',
                "type": 'empty_offers_list'
            }
            APIConfig.post(`update_impressions?user_id=${userID}`, payload).then(res => {
            })

            setTimeout(() => {
                window.location.replace('https://www.nsvz.ru/offers?filter_data=filters[$and][0][traffic_types][slug][$eq]=broker')
            }, 10)
        }
    }

    const handleNextOffer = (offerId) => {
        setLoadCard(true)
        const userID = localStorage.getItem('user_id') || ''
        const payload = {
            "offer_id": offerId,
            "type": 'exists'
        }
        APIConfig.post(`update_impressions?user_id=${userID}`, payload).then(res => {
            setTimeout(() => {
                setLoadCard(false)
            }, 10)
            let offers = offersList
            offers = offers.slice(1, offersList.length)
            setOffersList(offers)
            window.scrollTo({top: 160, behavior: 'smooth'});
            handleEmptyOffersList()
        })
    }

    const handleClickOffer = (offerId) => {
        const userID = localStorage.getItem('user_id') || ''
        const payload = {
            'user_id': localStorage.getItem('user_id'),
            "offer_id": offerId,
            "type": 'clicked'
        }
        APIConfig.post(`update_impressions?user_id=${userID}`, payload).then(res => {
            setTimeout(() => {
                let offers = offersList
                offers = offers.slice(1, offersList.length)
                setOffersList(offers)
                window.scrollTo({top: 160, behavior: 'smooth'});
                handleEmptyOffersList()
            }, 100)
        })
    }

    return (
        <>
            {showPreloader ?
                <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                    <p className='mt-3'>Подбираем персональные предложения...</p>
                </section>
                : <>
                    <PageHeader currentStep={4}/>
                    <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                        <div className='container d-flex flex-column justify-content-center align-items-center'>
                            <div className='row mt-2 mb-5'>
                                <div className="col-lg-10 mx-auto text-center">
                                    <h3 className="fs-1">Персональные предложения 🔥</h3>
                                    <p className="mb-0 text-danger">
                                        Для гарантированного получения займа, нужно оставить заявку минимум в 3 МФО
                                    </p>
                                </div>
                            </div>

                            {offersList.length ?
                                <div className='col-md-3 col-sm-12 mb-3 mb-md-0'>
                                    {loadCard ?
                                        <div className='text-center mb-4'>
                                            <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                                        </div>
                                        :
                                        <>
                                            <div onClick={() => handleClickOffer(offersList[0].id)}>
                                                <AdvCardItem
                                                    imgAlt={offersList[0].attributes.name}
                                                    imgSource={offersList[0].attributes.image_url}
                                                    imgTitle={offersList[0].attributes.name}
                                                    badgeText={offersList[0].attributes.badge.data.attributes.text}
                                                    badgeClass={offersList[0].attributes.badge.data.attributes.class}
                                                    offerLink={`/go/${offersList[0].id}`}
                                                    loanAmount={`до ${offersList[0].attributes.amount_to.toLocaleString()} руб.`}
                                                    cardText={`от ${offersList[0].attributes.rate}% в день`}
                                                    offerPosition={1}
                                                />
                                            </div>
                                            <div className='text-center'>
                                                <p className='mb-0'>Уже подавали заявку
                                                    в <strong>{offersList[0].attributes.name}</strong>?
                                                </p>
                                                <p>Нажмите пропустить, чтобы перейти к следующему МФО. </p>
                                                <button className={'btn form-control btn-warning-soft btn-lg'}
                                                        onClick={() => handleNextOffer(offersList[0].id)}>
                                                    Пропустить
                                                </button>
                                            </div>
                                        </>
                                    }


                                </div>
                                :
                                <div className=''>

                                </div>
                            }
                        </div>
                        {bannerSize && AddedAdsFin() &&
                            <div style={{padding: 10}}>
                                <ins className="dd15595d" data-cp-size={bannerSize}
                                     data-key="a1f0bcc783ed6f203335ebc0f8aa4b1d"/>
                            </div>
                        }
                    </section>
                    <PageFooter currentStep={4}/>
                </>
            }
        </>
    )
}

export default OffersListSectionTinderLayout