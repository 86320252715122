function MasterCardLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="54"
            fill="none"
            viewBox="0 0 100 54"
        >
            <g>
                <g>
                    <path
                        fill="#FF5A00"
                        d="M39.958 8.92H59.983999999999995V45.08H39.958z"
                    ></path>
                    <path
                        fill="#EB001B"
                        d="M41.29 27c0-7.347 3.437-13.867 8.71-18.08C46.12 5.853 41.224 4 35.884 4 23.235 4 13 14.287 13 27s10.235 23 22.884 23c5.34 0 10.235-1.853 14.116-4.92-5.28-4.154-8.71-10.733-8.71-18.08z"
                    ></path>
                    <path
                        fill="#F79E1B"
                        d="M87 27c0 12.713-10.235 23-22.884 23-5.34 0-10.235-1.853-14.116-4.92 5.34-4.22 8.71-10.733 8.71-18.08 0-7.347-3.437-13.867-8.71-18.08A22.653 22.653 0 0164.108 4C76.765 4 87 14.354 87 27z"
                    ></path>
                </g>
            </g>
        </svg>
    )
}

export default MasterCardLogo