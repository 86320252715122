import React, {useState, useEffect, useRef} from 'react';
import AdvCardItem from "./AdvCardItem";
import RegularCardItem from "./RegularCardItem";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import APIConfig from "../utils/api";
import {uuidv4, handleOffersFilter, AddedAdsFin} from "../utils";
import CustomAd from "./CustomAds";


function handleLinkParams() {
    const params = new URLSearchParams(window.location.search)
    const paramsDict = {}
    for (const param of params) {
        paramsDict[param[0]] = param[1]
    }
    if (Object.keys(paramsDict).length) {
        localStorage.setItem('query_params', JSON.stringify(paramsDict))
    }
}

function OffersListSectionBrokers({cardType = Math.random()}) {
    const adDivRef = useRef();
    const adDivRefBanner = useRef();
    const [showPreloader, setShowPreloader] = useState(true)
    const [offersList, setOffersList] = useState([])
    const [secondBanner, setSecondBanner] = useState(false)

    const [bannerSize, setBannerSize] = useState("970")
    useEffect(() => {
        if (window.innerWidth < 969) {
            setBannerSize('300')
        }
    }, [])

    const getUserOffers = () => {
        APIConfig.get(`get_user_offers?filters[$and][0][traffic_types][slug][$eq]=cpl`).then(res => {
            const randomOffers = res.data.offers_list.sort(function (a, b) {
                return 0.5 - Math.random()
            })
            setOffersList(randomOffers.slice(0, 1000))
            setShowPreloader(false)
        })
    }
    useEffect(() => {
        console.log('adDivRef.current ', adDivRef.current)
        if (!adDivRef.current) {
            return;
        }
        const script = document.createElement('script');
        script.src = 'https://front.adsfin.net/place/115560539458362111/';
        script.async = true;
        adDivRef.current.appendChild(script);

        // <ins id="place_115560539458362111"></ins><script type="text/javascript" async src="https://front.adsfin.net/place/115560539458362111/"></script>

    }, [showPreloader]);


    useEffect(() => {
        const OFFERS_LIST_ID = uuidv4()
        localStorage.setItem('offers_list_id', OFFERS_LIST_ID)
        if (localStorage.getItem('user_id') === null) {
            localStorage.setItem('user_id', uuidv4())
            localStorage.setItem('inited_from_offers_list', 'true')
        }
        handleLinkParams()

        setTimeout(() => {
            if (window.location.pathname === '/ofr') {
                setSecondBanner(true)
            }
            getUserOffers()
        }, 1000)

    }, [])
    return (
        <>
            {showPreloader ?
                <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'>
                    <img src={require('../assets/images/Rolling-1s-128px.gif')} width={48}/>
                    <p className='mt-3'>Подбираем персональные предложения...</p>
                </section>
                : <>
                    <PageHeader currentStep={4}/>
                    <section className='position-relative overflow-hidden pt-5 pt-lg-3 form-section__wrapper'>
                        <div className='container'>
                            <div className='row mt-2 mb-5'>
                                <div className="col-lg-8 mx-auto text-center">
                                    <h2 className="fs-1">Персональные предложения 🔥</h2>
                                    <p className="mb-0">
                                        Для гарантированного получения займа, нужно оставить заявку минимум в 3 МФО
                                    </p>
                                </div>

                            </div>

                            {cardType < 0.5 ?
                                <div className='row'>
                                    {offersList.map((offer, position) => {
                                        return (
                                            <>
                                                <div className='col-md-3 col-sm-12 mb-3 mb-md-0' key={offer.id}>
                                                    <AdvCardItem
                                                        imgAlt={offer.attributes.name}
                                                        imgSource={offer.attributes.image_url}
                                                        imgTitle={offer.attributes.name}
                                                        badgeText={offer.attributes.badge.data.attributes.text}
                                                        badgeClass={offer.attributes.badge.data.attributes.class}
                                                        offerLink={`/go/${offer.id}`}
                                                        loanAmount={`до ${offer.attributes.amount_to.toLocaleString()} руб.`}
                                                        cardText={`от ${offer.attributes.rate}% в день`}
                                                        offerPosition={position + 1}
                                                    />
                                                </div>
                                                {(position + 1) % 3 === 0 && <CustomAd/>}
                                            </>
                                        )
                                    })}
                                </div>
                                :
                                <div className='row'>
                                    {offersList.map((offer, position) => {
                                        return (
                                            <>
                                                <div className='col-md-3 col-sm-12 mb-3 mb-md-0' key={offer.id}>
                                                    <AdvCardItem
                                                        imgAlt={offer.attributes.name}
                                                        imgSource={offer.attributes.image_url}
                                                        imgTitle={offer.attributes.name}
                                                        badgeText={offer.attributes.badge.data.attributes.text}
                                                        badgeClass={offer.attributes.badge.data.attributes.class}
                                                        offerLink={`/go/${offer.id}`}
                                                        loanAmount={`до ${offer.attributes.amount_to.toLocaleString()} руб.`}
                                                        cardText={`от ${offer.attributes.rate}% в день`}
                                                        offerPosition={position + 1}
                                                    />
                                                </div>
                                                {(position + 1) % 3 === 0 && <CustomAd/>}
                                            </>
                                        )
                                    })}
                                </div>
                            }


                            <div ref={adDivRef} style={{paddingTop: 10}}>
                                <ins id="place_115560539458362111"/>
                            </div>


                        </div>
                    </section>
                    <PageFooter currentStep={4}/>
                </>
            }
        </>
    )
}

export default OffersListSectionBrokers