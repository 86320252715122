import React from 'react';
import MainLogo from "./logos/MainLogo";
import {Link} from "react-scroll";

function PageHeader({currentStep}) {
    const handleExit = () => {
        localStorage.removeItem('access_token')
        window.location.replace('/')
    }
    return (
        <nav className="navbar navbar-expand-md navbar-light fixed-top lg-header">
            <div className='container'>
                <a href={'/'}>
                    <MainLogo/>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse header__menu" id="navbarCollapse">
                    {}
                    {currentStep === 0 &&
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" to="headerSection" smooth={true} offset={-90}>
                                    Взять займ
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="paymentSection" smooth={true} offset={-90}>
                                    Условия
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="recommendationsSection" smooth={true} offset={-90}>
                                    Отзывы
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="FAQSection" smooth={true} offset={-90}>
                                    Вопрос-ответ
                                </Link>
                            </li>
                        </ul>
                    }
                    {currentStep === 9999 &&
                        <div className='pt-3 w-100 d-flex justify-content-end'>
                            <button className='btn btn-sm btn-outline-primary' onClick={() => handleExit()}>Выход</button>
                        </div>

                    }
                </div>
            </div>
        </nav>
    )
}

export default PageHeader