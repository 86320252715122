import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import PageHeader from "./PageHeader";
import FormSection from "./FormSection";
import PageFooter from "./PageFooter";
import {handleUserId} from "../utils";
import APIConfig from "../utils/api";

function OnlyFormLayout() {
    const [stepCount, setStepCount] = useState(parseInt(localStorage.getItem('current_step') || 1))
    const [userId, setUserId] = useState(localStorage.getItem('user_id') || handleUserId())
    const {register, handleSubmit, setError, formState: {errors}, control, setValue} = useForm();

    const stepHandler = (data) => {
        // updateUser(data, localStorage.getItem('user_id'))
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (stepCount <= 4) {
            const newStepCount = parseInt(localStorage.getItem('current_step')) + 1
            // data['current_step'] = stepCount + 1
            window.ym(85181272, 'reachGoal', `next_step_${newStepCount}`)
            setStepCount(newStepCount)
            localStorage.setItem('current_step', `${newStepCount}`)
            localStorage.setItem(`step_${newStepCount}`, JSON.stringify(data))
        }
    }

    const checkUserCode = (code, data) => {
        let phoneNumber = JSON.parse(localStorage.getItem('step_2')).phoneNumber
        phoneNumber = phoneNumber.replace(' (', '').replace(') ', '').replace('-', '').replace('_', '').replace(' ', '')
        console.log(phoneNumber)
        APIConfig.get(`check_auth_code?phone=${phoneNumber}&code=${code}&user_id=${localStorage.getItem('user_id')}`).then(res => {
            console.log(res)
            stepHandler(data)
        })
            .catch(err => {
                if (err.response.status === 404) {
                    setError('smsCode', {type: 'custom', message: 'указан неверный код, проверьте смс'});
                }
            })
    }

    const onSubmit = data => {
        console.log(data)

        if(stepCount === 1) {
            const initDataPayload = {
                'loan_amount': JSON.parse(localStorage.getItem('initedUserData')).loan_amount || {},
                'loan_period': JSON.parse(localStorage.getItem('initedUserData')).loan_period || {},
                'query_params': JSON.parse(localStorage.getItem('initedUserData')).query_params || {},
                'user_uid': JSON.parse(localStorage.getItem('initedUserData')).user_uid || {},
                'first_name': data.firstName,
                'last_name': data.lastName,
                'middle_name': data.middleName,
                'personal_accept': data.personalAccept,
                'phoneNumber': data.phoneNumber,
                'email': data.email,
                'birthDay': data.birthDay,
                'birthMonth': data.birthMonth,
                'birthYear': data.birthYear
            }
            console.log(initDataPayload)
            APIConfig.post(`init_user?user_id=${localStorage.getItem('user_id')}`, initDataPayload)
                .then(res => {
                stepHandler(data)
            }).catch(err => {
                if(err.response.status === 400) {
                    setError('phoneNumber', {type: 'alreadyExistPhone',
                        message: 'Номер телефона уже используется'
                    });
                }
            })
        }

        if (stepCount === 2) {
            checkUserCode(data.smsCode, data)
        }

        if(stepCount === 3) {
            APIConfig.patch(`update_user_passport_data?user_id=${localStorage.getItem('user_id')}`, data).then(res => {
                console.log(res)
                stepHandler(data)
            })
        }
        //
        if (stepCount === 4) {
            const fullRegionNameRegistration = JSON.parse(localStorage.getItem('fullRegionNameRegistration'))
            const fullRegionNameFact = JSON.parse(localStorage.getItem('fullRegionNameFact'))
            const addressPayload = {
                addressData: data,
                fullAddressData: {
                    fullRegionNameRegistration: fullRegionNameRegistration,
                    fullRegionNameFact: fullRegionNameFact
                }
            }
            APIConfig.patch(`update_user_address_data?user_id=${userId}`, addressPayload).then(res => {
                console.log(res)
                stepHandler(data)
            })
        }

    };


    useEffect(() => {
        if (stepCount > 4) {
            window.ym(85181272, 'reachGoal', `full_form`)
            localStorage.setItem('current_step', '4')
            window.location.replace(`/offers`)
        }
    }, [stepCount])

    return (
        <>
            {(stepCount > 0 && stepCount <= 4) &&
                <>
                    <PageHeader currentStep={stepCount}/>
                    <FormSection
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        stepCount={stepCount}/>
                    <PageFooter currentStep={stepCount}/>
                </>
            }
        </>
    );
}

export default OnlyFormLayout
