import React from 'react';

function RegularCardItem({
                             imgSource, imgAlt, imgTitle, badgeText, badgeClass,
                             loanAmount, cardText, offerLink, offerPosition
                         }) {
    return (
        <a href={`${offerLink}?ct=reg&pos=${offerPosition}`} target={'_blank'} rel={'noreferrer'}>
            <div className="card mb-3">
                <img
                    src={imgSource}
                    className="card-img-top"
                    alt={imgAlt}
                    title={imgTitle}
                />
                <div className="card-body text-center">
                    <div className="d-flex justify-content-between mb-2">
                    <span className={`${badgeClass} py-2`}>
                        {badgeText}
                    </span>
                    </div>
                    <h5 className="card-title mt-3">{loanAmount}</h5>
                    <p className="card-text">
                        {cardText}
                    </p>
                    <button className="btn btn-danger-soft form-control">
                        Получить деньги
                    </button>
                </div>
            </div>
        </a>
    )
}

export default RegularCardItem